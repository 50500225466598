import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import { Checkbox, FormControlLabel } from "@mui/material";

import { calculateEmissionByDCF } from "../helper"; 
import { summationOfKeys_Values } from "../../../components/BGHF/helper";

const Scope1EmissionsChart = (props) => {
  const chartRef = useRef(null);
  const [refresh, setRefresh] = useState(props)
  const admin_data = useSelector((state) => state.user.admindetail);
  const [initialData, setInitialData] = useState([]);
  const [emissionsData, setEmissionsData] = useState([]);
  useEffect(() => {
    setRefresh(props)
  }, [props])
  useEffect(() => {
    renderChart(emissionsData)
  }, [emissionsData])
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const submissions = refresh?.submissions || []

        // Extract and filter relevant data
        const relevantData = submissions.filter((item) => {
          const [month, year] = item.reporting_period[0].split("-").map(Number);

          // Convert reporting period to comparable values
          const periodValue = year * 100 + month; // E.g., "04-2023" -> 202304

          // Define range for filtering (04-2023 to 03-2024)
          const startPeriod = 202304; // April 2023
          const endPeriod = 202403; // March 2024
          return (
            periodValue >= startPeriod &&
            periodValue <= endPeriod
          );
        });

        const transformedData = relevantData?.map((item, index) => {
          console.log(index)
          const scope1Submission = calculateEmissionByDCF(
            refresh?.ef || [],
            item,
            item?.dcf?.standardId || null,
            item?.dcf?.categoryId || null,
            item?.dcf?.subCategoryDpIds || [],
            item?.dcf?.calculationDpIds || [],
            item?.dcf?.subCategoryOrder || [],
            refresh?.subcat || { one: [], two: [], three: [], four: [] }
          )


          let stationary = item.dcfId === 11 ? (scope1Submission?.response?.reduce((sum, d) => sum?.emission || 0 + d?.emission || 0, 0)) : 0;

          const fugitive = item.dcfId === 10 ? (scope1Submission?.response?.reduce((sum, d) => sum?.emission || 0 + d?.emission || 0, 0)) : 0;
       

         
          if (index === 0) {
            console.log((refresh?.sap?.fuel?.reduce((a, b) => { return a + b.emission }, 0) || 0))
            stationary += (refresh?.sap?.fuel?.reduce((a, b) => { return a + b.emission }, 0) || 0)

          }
      
console.log(stationary,fugitive)

          const year = parseInt(item.reporting_period[0].split("-")[1], 10);

          return {
            year,
           stationary,fugitive
          };
        });

        // Remove duplicate years and retain unique entries
        const uniqueTransformedData = summationOfKeys_Values(transformedData, ['year'], ['stationary', 'fugitive'])

        console.log(uniqueTransformedData)
        if (isMounted) {
          setInitialData(uniqueTransformedData);
          setEmissionsData(uniqueTransformedData);
        }
      } catch (error) {
        console.error("Error fetching emissions data:", error);
      }
    };
    if (refresh?.ef.length && refresh?.subcat?.one?.length) {
      fetchData();
    }

    console.log(refresh)
    return () => {
      isMounted = false;
    };
  }, [refresh]);

  const [visibleCategories, setVisibleCategories] = useState([
    "Stationary",
    "Fugitive",
  ]);



  useEffect(() => {
    renderChart(emissionsData);
  }, [visibleCategories]);

  const handleToggle = (category) => {
    setVisibleCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const renderChart = (data) => {
    console.log(data)
    const width = 600;
    const height = 300;
    const margin = { top: 50, right: 30, bottom: 50, left: 60 };

    d3.select(chartRef.current).selectAll("*").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const filteredData = data.map((d) => ({
      year: d.year,
      Stationary: visibleCategories.includes("Stationary") ? d.stationary || 0 : 0,
      Fugitive: visibleCategories.includes("Fugitive") ? d.fugitive || 0 : 0
    }));

    const xScale = d3
      .scaleBand()
      .domain(filteredData.map((d) => d.year))
      .range([margin.left, width - margin.right])
      .padding(0.4);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(filteredData, (d) => d.Stationary + d.Fugitive) || 10])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const colorScale = d3
      .scaleOrdinal()
      .domain(["Stationary", "Fugitive"])
      .range(["#9b59b6", "#8e44ad"]);

    const stack = d3
      .stack()
      .keys(["Stationary", "Fugitive"])
      .value((d, key) => d[key]);

    const series = stack(filteredData);

    svg
      .append("g")
      .selectAll("g")
      .data(series)
      .enter()
      .append("g")
      .attr("fill", (d) => colorScale(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.data.year))
      .attr("y", (d) => yScale(d[1]))
      .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
      .attr("width", xScale.bandwidth());

    // Axes
    svg
      .append("g")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(xScale));

    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(yScale));

    // Labels
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 10)
      .attr("text-anchor", "middle")
      .text("Year");

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", 20)
      .attr("text-anchor", "middle")
      .text("Emissions in tCO2e");
  };

  return (
    <div>
      <div>
        <h3 style={{ margin: "25px", fontSize: "18px" }}>Scope 1 Emissions</h3>
        <p style={{ margin: "25px", fontSize: "14px" }}>
          Direct emissions from the operations that are owned or controlled.
        </p>
      </div>

      {/* Chart */}
      <div ref={chartRef} style={{ textAlign: "center" }}></div>

      {/* Legend with Material-UI Checkboxes */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {["Stationary", "Fugitive"].map((category) => (
          <FormControlLabel
            key={category}
            control={
              <Checkbox
                checked={visibleCategories.includes(category)}
                onChange={() => handleToggle(category)}
                style={{ color: "#6c3483" }} // Purple checkbox color
              />
            }
            label={category}
          />
        ))}
      </div>
    </div>
  );
};

export default Scope1EmissionsChart;
