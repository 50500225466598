import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Checkbox, FormControlLabel } from "@mui/material";

const EmissionsTrendGraph = () => {
  const graphRef = useRef(null);

  const [visibleScopes, setVisibleScopes] = useState({
    scope1: true,
    scope2: true,
    scope3: true,
  });

  const data = [
    { year: 2018, scope1: 100, scope2: 150, scope3: 300 },
    { year: 2019, scope1: 120, scope2: 130, scope3: 290 },
    { year: 2020, scope1: 140, scope2: 170, scope3: 310 },
    { year: 2021, scope1: 160, scope2: 200, scope3: 330 },
    { year: 2022, scope1: 180, scope2: 210, scope3: 350 },
  ];

  // Define colors globally within the component
  const colors = { scope1: "blue", scope2: "green", scope3: "orange" };

  useEffect(() => {
    renderGraph();
  }, [visibleScopes]);

  const renderGraph = () => {
    const width = 600;
    const height = 300;
    const margin = { top: 50, right: 50, bottom: 50, left: 80 };

    // Clear previous SVG content
    d3.select(graphRef.current).selectAll("*").remove();

    const svg = d3
      .select(graphRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .style("display", "block");

    const xScale = d3
      .scaleLinear()
      .domain(d3.extent(data, (d) => d.year))
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => Math.max(d.scope1, d.scope2, d.scope3))])
      .range([height - margin.bottom, margin.top]);

    const lineGenerator = (scope) =>
      d3
        .line()
        .x((d) => xScale(d.year))
        .y((d) => yScale(d[scope]));

    // Draw lines based on visibility
    Object.keys(visibleScopes).forEach((scope) => {
      if (visibleScopes[scope]) {
        svg
          .append("path")
          .datum(data)
          .attr("d", lineGenerator(scope))
          .attr("fill", "none")
          .attr("stroke", colors[scope])
          .attr("stroke-width", 2);
      }
    });

    // Add axes
    svg
      .append("g")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(xScale).ticks(5).tickFormat(d3.format("d")));

    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(yScale).ticks(5));

    // Add axis labels
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 10)
      .text("Year")
      .style("text-anchor", "middle")
      .style("font-size", "14px");

    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", 20)
      .attr("transform", "rotate(-90)")
      .text("Emissions in tCO2e")
      .style("text-anchor", "middle")
      .style("font-size", "14px");
  };

  const handleCheckboxChange = (scope) => {
    setVisibleScopes((prev) => ({ ...prev, [scope]: !prev[scope] }));
  };

  return (
    <div>
      {/* Title and description */}
      <div style={{ marginTop: "30px" }}>
        <h3 style={{ margin: "25px", fontSize: "18px" }}>Emissions Trend</h3>
        <p style={{ margin: "25px", fontSize: "14px" }}>
          Changes in GHG Emissions across the time-period
        </p>
      </div>
      {/* Graph container */}
      <div ref={graphRef}></div>

      {/* Dynamic legend with checkboxes */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        {Object.keys(visibleScopes).map((scope) => (
          <FormControlLabel
            key={scope}
            control={
              <Checkbox
                checked={visibleScopes[scope]}
                onChange={() => handleCheckboxChange(scope)}
                style={{ color: colors[scope] }}
              />
            }
            label={scope.charAt(0).toUpperCase() + scope.slice(1)}
          />
        ))}
      </div>
    </div>
  );
};

export default EmissionsTrendGraph;
