import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import * as d3 from "d3";

const data = [
  { scope: "Scope 1", target: 800, progress: 600, range: [400, 700, 900] },
  { scope: "Scope 2", target: 1200, progress: 1100, range: [800, 1000, 1300] },
  { scope: "Scope 3", target: 2000, progress: 1800, range: [1400, 1700, 2200] },
];

const colors = {
  red: "#e74c3c",
  yellow: "#f39c12",
  green: "#16a085",
};

const BulletGraph = () => {
  const [visibleScopes, setVisibleScopes] = useState({
    scope1: true,
    scope2: true,
    scope3: true,
  });

  const renderBulletGraph = () => {
    const margin = { top: 10, right: 50, bottom: 30, left: 100 };
    const width = 600 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;
    const barHeight = 20;

    d3.select("#bulletGraph").selectAll("*").remove();

    const svg = d3
      .select("#bulletGraph")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const visibleData = data.filter(
      (d) => visibleScopes[d.scope.toLowerCase().replace(" ", "")]
    );

    const yScale = d3
      .scaleBand()
      .domain(visibleData.map((d) => d.scope))
      .range([0, height])
      .padding(0.3);

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data.map((d) => Math.max(...d.range)))])
      .range([0, width]);

    svg
      .selectAll("g.bullet")
      .data(visibleData)
      .enter()
      .append("g")
      .attr("class", "bullet")
      .attr("transform", (d) => `translate(0, ${yScale(d.scope)})`)
      .each(function (d) {
        const g = d3.select(this);

        // Ranges
        g.selectAll(".range")
          .data(d.range)
          .enter()
          .append("rect")
          .attr("class", "range")
          .attr("x", 0)
          .attr("y", (r, i) => i * (barHeight / 3))
          .attr("width", (r) => xScale(r))
          .attr("height", barHeight)
          .attr("fill", (r, i) => {
            return i === 0
              ? colors.red
              : i === 1
              ? colors.yellow
              : colors.green;
          });

        // Progress
        g.append("rect")
          .attr("class", "progress")
          .attr("x", 0)
          .attr("y", barHeight / 4)
          .attr("width", xScale(d.progress))
          .attr("height", barHeight / 2)
          .attr("fill", "#34495e");

        // Target Marker
        g.append("line")
          .attr("class", "target")
          .attr("x1", xScale(d.target))
          .attr("x2", xScale(d.target))
          .attr("y1", 0)
          .attr("y2", barHeight)
          .attr("stroke", "black")
          .attr("stroke-width", 2);
      });

    // Y-axis
    svg
      .append("g")
      .call(d3.axisLeft(yScale))
      .selectAll("text")
      .attr("font-size", "12px");
  };

  const handleCheckboxChange = (scope) => {
    setVisibleScopes((prev) => ({ ...prev, [scope]: !prev[scope] }));
  };

  React.useEffect(() => {
    renderBulletGraph();
  }, [visibleScopes]);

  return (
    <div style={{ fontFamily: "Lato" }}>
      <h3 style={{ margin: "25px", fontSize: "18px" }}>
        Emissions Reduction Progress
      </h3>
      <p style={{ margin: "25px", fontSize: "14px" }}>
        Progress against GHG emissions reduction targets.{" "}
      </p>
      <div
        id="bulletGraph"
        style={{ marginBottom: "20px", textAlign: "center" }}
      ></div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {data.map((d) => {
          const scopeKey = d.scope.toLowerCase().replace(" ", "");
          return (
            <FormControlLabel
              key={scopeKey}
              control={
                <Checkbox
                  checked={visibleScopes[scopeKey]}
                  onChange={() => handleCheckboxChange(scopeKey)}
                  sx={{
                    color:
                      colors[
                        d.range.indexOf(d.range[0]) === 0 ? "red" : "green"
                      ],
                    "&.Mui-checked": {
                      color: colors.green,
                    },
                  }}
                />
              }
              label={
                <span style={{ color: "#000", fontSize: "14px" }}>
                  {d.scope}
                </span>
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default BulletGraph;
