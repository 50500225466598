import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Checkbox, FormControlLabel } from "@mui/material";
import { calculateEmissionByDCF } from "../helper";
import { useSelector } from "react-redux";
import { CommentBankSharp } from "@mui/icons-material";
import { summationOfKeys_Values } from "../../../components/BGHF/helper";
import useForceUpdate from "use-force-update";
// Updated data: emissions by Scope for 2023 and 2024 only


const TotalScopeStacked = (props) => {

  const [refresh, setRefresh] = useState(props)
  const chartRef = useRef(null);
  const admin_data = useSelector((state) => state.user.admindetail);
  const [initialData, setInitialData] = useState([]);
  const [emissionsData, setEmissionsData] = useState([]);
  const forceUpdate = useForceUpdate()
  useEffect(() => {
    setRefresh(props)
  }, [props])
  useEffect(() => {
    renderStackedBarChart(emissionsData)
  }, [emissionsData])
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const submissions = refresh?.submissions || []

        // Extract and filter relevant data
        const relevantData = submissions.filter((item) => {
          const [month, year] = item.reporting_period[0].split("-").map(Number);

          // Convert reporting period to comparable values
          const periodValue = year * 100 + month; // E.g., "04-2023" -> 202304

          // Define range for filtering (04-2023 to 03-2024)
          const startPeriod = 202304; // April 2023
          const endPeriod = 202403; // March 2024
          return (
            periodValue >= startPeriod &&
            periodValue <= endPeriod
          );
        });

        const transformedData = relevantData?.map((item, index) => {
          const scope1Submission = calculateEmissionByDCF(
            refresh?.ef || [],
            item,
            item?.dcf?.standardId || null,
            item?.dcf?.categoryId || null,
            item?.dcf?.subCategoryDpIds || [],
            item?.dcf?.calculationDpIds || [],
            item?.dcf?.subCategoryOrder || [],
            refresh?.subcat || { one: [], two: [], three: [], four: [] }
          )


          let scope1Stationary = item.dcfId === 11 ? (scope1Submission?.response?.reduce((sum, d) => sum?.emission || 0 + d?.emission || 0, 0)) : 0;

          const scope1Fugitive = item.dcfId === 10 ? (scope1Submission?.response?.reduce((sum, d) => sum?.emission || 0 + d?.emission || 0, 0)) : 0;
          console.log(scope1Stationary, scope1Fugitive)

         
          if (index === 0) {
            console.log((refresh?.sap?.fuel?.reduce((a, b) => { return a + b.emission }, 0) || 0))
            scope1Stationary += (refresh?.sap?.fuel?.reduce((a, b) => { return a + b.emission }, 0) || 0)

          }
          const   scope1 = scope1Stationary + scope1Fugitive
          // Scope 2 calculation
          const scope2Submission = calculateEmissionByDCF(
            refresh?.ef || [],
            item,
            item?.dcf?.standardId || null,
            item?.dcf?.categoryId || null,
            item?.dcf?.subCategoryDpIds || [],
            item?.dcf?.calculationDpIds || [],
            item?.dcf?.subCategoryOrder || [],
            refresh?.subcat || { one: [], two: [], three: [], four: [] }
          );
          console.log(scope2Submission, "functionResult");
          const scope2 = item.dcfId === 257 ? (scope2Submission?.response?.reduce((sum, d) => sum?.emission || 0 + d?.emission || 0, 0)) : 0;
          console.log(scope1Submission)


          const scope3 = 0;

          const year = parseInt(item.reporting_period[0].split("-")[1], 10);

          return {
            year,
            scope1,
            scope2,
            scope3,
          };
        });

        // Remove duplicate years and retain unique entries
        const uniqueTransformedData = summationOfKeys_Values(transformedData, ['year'], ['scope1', 'scope2', 'scope3'])

        console.log(uniqueTransformedData)
        if (isMounted) {
          setInitialData(uniqueTransformedData);
          setEmissionsData(uniqueTransformedData);
        }
      } catch (error) {
        console.error("Error fetching emissions data:", error);
      }
    };
    if (refresh?.ef.length && refresh?.subcat?.one?.length) {
      fetchData();
    }

    console.log(refresh)
    return () => {
      isMounted = false;
    };
  }, [refresh]);

  const [visibleScopes, setVisibleScopes] = useState({
    scope1: true,
    scope2: true,
    scope3: true,
  });

  useEffect(() => {
    console.log(emissionsData)
    renderStackedBarChart(emissionsData);
  }, [visibleScopes]);

  const handleToggle = (scope) => {
    setVisibleScopes((prev) => ({
      ...prev,
      [scope]: !prev[scope],
    }));
  };

  const renderStackedBarChart = (data) => {
    const margin = { top: 30, right: 30, bottom: 40, left: 60 }; // Increased bottom margin for legend
    const width = 600 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    // Set up the SVG container
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Filter data based on visibleScopes
    const filteredData = data.map((d) => ({
      year: d.year,
      scope1: visibleScopes.scope1 ? d.scope1 : 0,
      scope2: visibleScopes.scope2 ? d.scope2 : 0,
      scope3: visibleScopes.scope3 ? d.scope3 : 0,
    }));

    // Define the X and Y scales
    const x = d3
      .scaleBand()
      .domain(filteredData.map((d) => d.year))
      .range([0, width])
      .padding(0.1);
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(filteredData, (d) => d.scope1 + d.scope2 + d.scope3)])
      .nice()
      .range([height, 0]);

    // Define color scale for the stacked bars
    const color = d3
      .scaleOrdinal()
      .domain(["scope1", "scope2", "scope3"])
      .range(["#e38322", "#f2c79c", "#166963"]);

    // Stack the data
    const stack = d3.stack().keys(["scope1", "scope2", "scope3"]);
    const stackedData = stack(filteredData);

    // Create the X and Y axes
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));

    svg.append("g").attr("class", "y-axis").call(d3.axisLeft(y).ticks(5));

    svg
      .append("text")
      .attr("x", width / 2 - 20)
      .attr("y", height - margin.bottom + 70)
      .attr("text-anchor", "middle")
      .text("Year");

    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", -30)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Emissions in tCO2e");

    // Add the bars for the stacked chart (setting width of bars to 60)
    svg
      .selectAll(".bar")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("class", "bar")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("transform", `translate(40,0)`)
      .attr("x", (d) => x(d.data.year))
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", 60); // Set width of bars to 60

  };

  return (
    <div>
      <div>
        <h3 style={{ fontSize: "18px", margin: "25px" }}>
          Total Emissions by Scope (2023 & 2024)
        </h3>
        <div style={{ margin: "25px" }}>
          Breakdown of total emissions for 2023 and 2024.
        </div>
      </div>

      {/* Chart */}
      <div ref={chartRef}></div>

      {/* Legend with Material-UI Checkboxes */}
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {["scope1", "scope2", "scope3"].map((scope) => (
          <FormControlLabel
            key={scope}
            control={
              <Checkbox
                checked={visibleScopes[scope]}
                onChange={() => handleToggle(scope)}
                style={{
                  color: d3
                    .scaleOrdinal()
                    .domain(["scope1", "scope2", "scope3"])
                    .range(["#e38322", "#f2c79c", "#166963"])(scope),
                }}
              />
            }
            label={
              scope === "scope1"
                ? "Scope 1"
                : scope === "scope2"
                  ? "Scope 2"
                  : "Scope 3"
            }
          />
        ))}
      </div>
    </div>
  );
};

export default TotalScopeStacked;
