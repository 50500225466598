import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { year: "2020", men: 150, women: 50 },
  { year: "2021", men: 160, women: 60 },
  { year: "2022", men: 170, women: 80 },
  { year: "2023", men: 180, women: 90 },
];

const DiversityByGender = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 50, right: 30, bottom: 150, left: 40 }; // Increased bottom margin to accommodate legends

    // Clear any previous chart
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Prepare the data for stacking (absolute values for men and women)
    const keys = ["men", "women"];
    const stackedData = d3.stack().keys(keys)(data);

    // X Scale
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.year))
      .range([0, chartWidth])
      .padding(0.4);

    // Y Scale
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(stackedData[stackedData.length - 1], (d) => d[1])])
      .nice()
      .range([chartHeight, 0]);

    // Color Scale
    const color = d3.scaleOrdinal().domain(keys).range(["#66c2a5", "#fc8d62"]); // Subtle colors for men and women

    // Draw Axes
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x));

    g.append("g").call(d3.axisLeft(y));

    // Add X-Axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Year"); // X-Axis label

    // Add Y-Axis label (rotated vertically)
    g.append("text")
      .attr("transform", "rotate(-90)") // Rotate the text by -90 degrees to make it vertical
      .attr("x", -chartHeight / 2) // Adjust positioning for the rotated label
      .attr("y", -40) // Adjust to place the label properly
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Number of Employees"); // Y-Axis label

    // Add stacked bars (men and women)
    g.selectAll("g.stack")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.data.year))
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", 60) // Set the bar width to 60
      .append("title") // Tooltip
      .text((d) => `${d.key}: ${d[1] - d[0]}`);

    // Add the legend at the bottom
    const legend = svg
      .append("g")
      .attr(
        "transform",
        `translate(${width / 2 - 30},${height - margin.bottom + 50})`
      ); // Move legend to the bottom

    legend
      .selectAll("rect")
      .data(keys)
      .enter()
      .append("rect")
      .attr("x", (d, i) => i * 80) // Adjust spacing between legend items
      .attr("y", 0)
      .attr("width", 15)
      .attr("height", 15)
      .attr("fill", (d) => color(d));

    legend
      .selectAll("text")
      .data(keys)
      .enter()
      .append("text")
      .attr("x", (d, i) => i * 80 + 20) // Adjust position of text relative to the legend box
      .attr("y", 12)
      .style("font-size", "14px")
      .text((d) => d.charAt(0).toUpperCase() + d.slice(1)); // Capitalizing 'men' and 'women'
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Diversity of Employees by Gender
      </h3>
      <div style={{ margin: "25px" }}>
        Comparing trends over time for different categories (e.g., sales by
        product category over time)
      </div>
      <div
        ref={chartRef}
        style={{ display: "flex", justifyContent: "center" }}
      />
    </>
  );
};

export default DiversityByGender;
