import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Checkbox, FormControlLabel } from "@mui/material";

const BiodiversityBubbleChart = () => {
  const graphRef = useRef(null);

  const [visibleScopes, setVisibleScopes] = useState({
    Region: true,
    "Market Share": true,
    "Profit Margin": true,
  });

  const data = [
    { scope: "Region", source: "Fuel", value: 100 },
    { scope: "Region", source: "Electricity", value: 120 },
    { scope: "Market Share", source: "Fuel", value: 150 },
    { scope: "Market Share", source: "Electricity", value: 170 },
    { scope: "Profit Margin", source: "Transport", value: 300 },
    { scope: "Profit Margin", source: "Waste", value: 330 },
  ];

  const colors = {
    Region: "#4f94cd",
    "Market Share": "#6db86b",
    "Profit Margin": "#f39c12",
  };

  useEffect(() => {
    renderGraph();
  }, [visibleScopes]);

  const renderGraph = () => {
    const width = 800;
    const height = 400;
    const margin = { top: 50, right: 50, bottom: 50, left: 80 };

    d3.select(graphRef.current).selectAll("*").remove();

    const svg = d3
      .select(graphRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .style("display", "block")
      .style("margin", "0 auto");

    const xScale = d3
      .scaleBand()
      .domain(["Region", "Market Share", "Profit Margin"])
      .range([margin.left, width - margin.right])
      .padding(0.1);

    const yScale = d3
      .scaleBand()
      .domain(["Fuel", "Electricity", "Transport", "Waste"])
      .range([height - margin.bottom, margin.top])
      .padding(0.1);

    const radiusScale = d3
      .scaleSqrt()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([10, 50]);

    svg
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.scope) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.source) + yScale.bandwidth() / 2)
      .attr("r", (d) => radiusScale(d.value))
      .attr("fill", (d) => colors[d.scope])
      .attr("opacity", (d) => (visibleScopes[d.scope] ? 1 : 0.2));

    svg
      .append("g")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(xScale));

    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(yScale));

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 10)
      .text("Severity Factor")
      .style("text-anchor", "middle")
      .style("font-size", "14px");

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", margin.left / 2)
      .text("Species Impacted")
      .style("text-anchor", "middle")
      .style("font-size", "14px");
  };

  const handleCheckboxChange = (scope) => {
    setVisibleScopes((prev) => ({ ...prev, [scope]: !prev[scope] }));
  };

  return (
    <div>
      <div style={{ marginTop: "30px" }}>
        <h3 style={{ margin: "0", fontSize: "18px" }}>
          Biodiversity Species Impacted
        </h3>
        <p style={{ margin: "0", fontSize: "14px" }}>
          Number of species impacted during operations
        </p>
      </div>
      <div ref={graphRef}></div>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        {Object.keys(visibleScopes).map((scope) => (
          <FormControlLabel
            key={scope}
            control={
              <Checkbox
                checked={visibleScopes[scope]}
                onChange={() => handleCheckboxChange(scope)}
                style={{ color: colors[scope] }}
              />
            }
            label={scope}
          />
        ))}
      </div>
    </div>
  );
};

export default BiodiversityBubbleChart;
