import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Checkbox, FormControlLabel } from "@mui/material";

const Scope3EmissionsChart = () => {
  const chartRef = useRef(null);

  // Define all 15 subcategories here
  const subcategories = [
    "Purchased goods and services",
    "Capital goods",
    "Fuel- and energy-related activities",
    "Upstream transportation and distribution",
    "Waste generated in operations",
    "Business travel",
    "Employee commuting",
    "Upstream leased assets",
    "Downstream transportation and distribution",
    "Processing of sold products",
    "Use of sold products",
    "End-of-life treatment of sold products",
    "Downstream leased assets",
    "Franchises",
    "Investments",
  ];

  const emissionsData = [
    { category: "Purchased goods and services", year: 2023, emissions: 200 },
    { category: "Purchased goods and services", year: 2024, emissions: 220 },
    { category: "Capital goods", year: 2023, emissions: 150 },
    { category: "Capital goods", year: 2024, emissions: 170 },
    {
      category: "Fuel- and energy-related activities",
      year: 2023,
      emissions: 120,
    },
    {
      category: "Fuel- and energy-related activities",
      year: 2024,
      emissions: 130,
    },
    {
      category: "Upstream transportation and distribution",
      year: 2023,
      emissions: 90,
    },
    {
      category: "Upstream transportation and distribution",
      year: 2024,
      emissions: 100,
    },
    { category: "Waste generated in operations", year: 2023, emissions: 55 },
    { category: "Waste generated in operations", year: 2024, emissions: 60 },
    { category: "Business travel", year: 2023, emissions: 80 },
    { category: "Business travel", year: 2024, emissions: 85 },
    { category: "Employee commuting", year: 2023, emissions: 75 },
    { category: "Employee commuting", year: 2024, emissions: 80 },
    { category: "Upstream leased assets", year: 2023, emissions: 45 },
    { category: "Upstream leased assets", year: 2024, emissions: 50 },
    {
      category: "Downstream transportation and distribution",
      year: 2023,
      emissions: 60,
    },
    {
      category: "Downstream transportation and distribution",
      year: 2024,
      emissions: 65,
    },
    { category: "Processing of sold products", year: 2023, emissions: 100 },
    { category: "Processing of sold products", year: 2024, emissions: 110 },
    { category: "Use of sold products", year: 2023, emissions: 200 },
    { category: "Use of sold products", year: 2024, emissions: 210 },
    {
      category: "End-of-life treatment of sold products",
      year: 2023,
      emissions: 150,
    },
    {
      category: "End-of-life treatment of sold products",
      year: 2024,
      emissions: 160,
    },
    { category: "Downstream leased assets", year: 2023, emissions: 130 },
    { category: "Downstream leased assets", year: 2024, emissions: 140 },
    { category: "Franchises", year: 2023, emissions: 50 },
    { category: "Franchises", year: 2024, emissions: 55 },
    { category: "Investments", year: 2023, emissions: 40 },
    { category: "Investments", year: 2024, emissions: 45 },
  ];

  const [visibleCategories, setVisibleCategories] = useState(subcategories);

  // Define colors for all 15 categories
  const colorScale = d3
    .scaleOrdinal()
    .domain(subcategories)
    .range([
      "#e74c3c",
      "#c0392b",
      "#b03a2e",
      "#922b21",
      "#7f3b2e",
      "#d35400",
      "#e67e22",
      "#f39c12",
      "#f1c40f",
      "#2ecc71",
      "#27ae60",
      "#16a085",
      "#1abc9c",
      "#3498db",
      "#9b59b6",
    ]);

  useEffect(() => {
    renderChart();
  }, [visibleCategories]);

  const handleToggle = (category) => {
    setVisibleCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const renderChart = () => {
    const width = 600;
    const height = 300;
    const margin = { top: 50, right: 50, bottom: 30, left: 80 };

    // Clear existing content
    d3.select(chartRef.current).selectAll("*").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height); // Extra height for the legend

    // Filter data based on visible categories
    const filteredData = emissionsData.filter((d) =>
      visibleCategories.includes(d.category)
    );

    const nestedData = d3.groups(filteredData, (d) => d.year);

    const stackData = nestedData.map(([year, data]) => ({
      year,
      ...subcategories.reduce((acc, category) => {
        acc[category] =
          data.find((d) => d.category === category)?.emissions || 0;
        return acc;
      }, {}),
    }));

    const stackGenerator = d3
      .stack()
      .keys(subcategories)
      .value((d, key) => (visibleCategories.includes(key) ? d[key] : 0));

    const series = stackGenerator(stackData);

    // Scales
    const xScale = d3
      .scaleBand()
      .domain(stackData.map((d) => d.year))
      .range([margin.left, width - margin.right])
      .padding(0.4);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(series, (s) => d3.max(s, (d) => d[1]))])
      .nice()
      .range([height - margin.bottom, margin.top]);

    // Axes
    svg
      .append("g")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(xScale).tickFormat((d) => `${d}`));

    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(
        d3
          .axisLeft(yScale)
          .ticks(6)
          .tickFormat((d) => `${d}`)
      );

    // Bars
    svg
      .append("g")
      .selectAll("g")
      .data(series)
      .enter()
      .append("g")
      .attr("fill", (d) => colorScale(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.data.year) + (xScale.bandwidth() - 60) / 2)
      .attr("y", (d) => yScale(d[1]))
      .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
      .attr("width", 60);

    // Axis labels
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - margin.bottom + 40)
      .attr("text-anchor", "middle")
      .text("Emissions in tCO2e");

    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", 20)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Scope 3 Categories");
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div>
        <h3 style={{ margin: "25px", fontSize: "18px" }}>Scope 3 Emissions</h3>
        <p style={{ margin: "25px", fontSize: "14px" }}>
          Emissions across various categories.
        </p>
        <div ref={chartRef}></div>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {subcategories.map((category) => (
          <FormControlLabel
            key={category}
            control={
              <Checkbox
                checked={visibleCategories.includes(category)}
                onChange={() => handleToggle(category)}
                size="small"
                style={{ margin: "5px", fontSize: "12px" }}
              />
            }
            label={category}
            labelPlacement="top"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "12px",
            }}
          />
        ))}
      </div> */}
    </div>
  );
};

export default Scope3EmissionsChart;
