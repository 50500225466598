import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { interpolateYlOrRd } from "d3-scale-chromatic"; // Import the color scale

const dummyData = {
  months: [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ],
  locations: ["Hosur", "Mysore", "Nalgargh"],
  emissions: {
    Hosur: [50, 60, 70, 65, 80, 90, 100, 110, 120, 115, 130, 140],
    Mysore: [55, 65, 75, 80, 85, 95, 105, 115, 125, 120, 135, 145],
    Nalgargh: [60, 70, 80, 85, 90, 100, 110, 120, 130, 125, 140, 150],
  },
};

const HeatmapTime = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderHeatMap();
  }, []);

  const renderHeatMap = () => {
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const width = 600 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define the color scale for the heatmap
    const colorScale = d3
      .scaleSequential(interpolateYlOrRd)
      .domain([
        d3.min(Object.values(dummyData.emissions).flat()),
        d3.max(Object.values(dummyData.emissions).flat()),
      ]);

    const months = dummyData.months;
    const locations = dummyData.locations;

    // Define the X and Y scales
    const xScale = d3
      .scaleBand()
      .domain(months)
      .range([0, width])
      .padding(0.05);
    const yScale = d3
      .scaleBand()
      .domain(locations)
      .range([0, height])
      .padding(0.05);

    // Add X axis (Months)
    svg
      .append("g")
      .selectAll(".x-axis")
      .data(months)
      .enter()
      .append("text")
      .attr("x", (d, i) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .text((d) => d)
      .style("font-size", "10px");

    // Add Y axis (Locations)
    svg
      .append("g")
      .selectAll(".y-axis")
      .data(locations)
      .enter()
      .append("text")
      .attr("x", -23)
      .attr("y", (d, i) => yScale(d) + yScale.bandwidth() / 2)
      .attr("text-anchor", "middle")
      .text((d) => d)
      .style("font-size", "10px")
      .style("dominant-baseline", "middle");

    // Create the heatmap cells
    svg
      .selectAll("rect")
      .data(
        locations.flatMap((location, i) =>
          months.map((month, j) => ({
            location,
            month,
            value: dummyData.emissions[location][j],
          }))
        )
      )
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.month))
      .attr("y", (d) => yScale(d.location))
      .attr("width", xScale.bandwidth())
      .attr("height", yScale.bandwidth())
      .style("fill", (d) => colorScale(d.value))
      .on("mouseover", (event, d) => {
        d3.select(event.currentTarget).style("opacity", 0.7);
        svg
          .append("text")
          .attr("x", xScale(d.month) + xScale.bandwidth() / 2)
          .attr("y", yScale(d.location) + yScale.bandwidth() / 2)
          .attr("text-anchor", "middle")
          .attr("dy", ".35em")
          .style("font-size", "12px")
          .style("fill", "black")
          .text(d.value);
      })
      .on("mouseout", (event, d) => {
        d3.select(event.currentTarget).style("opacity", 1);
        svg.selectAll("text").remove();
      });
  };

  return (
    <div>
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "25px",
        }}
      >
        Heat Map for Emissions
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Emissions intensity in each location .
        </div>
      </div>
      <div ref={chartRef} style={{ textAlign: "center" }} />

      {/* Legends */}
      <div
        style={{ textAlign: "center", marginTop: "20px", marginBottom: "15px" }}
      >
        <span style={{ fontSize: "14px" }}>
          Color scale represents emissions intensity: higher emissions have
          darker shades. (2023-24)
        </span>
      </div>
    </div>
  );
};

export default HeatmapTime;
