import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from "recharts";

const CorruptionRiskTrainingGraph = () => {
  // Sample data for the graph with different corruption risk levels
  const data = [
    { operation: "Operation A", trainingHours: 10, riskLevel: "Low" },
    { operation: "Operation B", trainingHours: 20, riskLevel: "Medium" },
    { operation: "Operation C", trainingHours: 30, riskLevel: "High" },
    { operation: "Operation D", trainingHours: 40, riskLevel: "Low" },
    { operation: "Operation E", trainingHours: 35, riskLevel: "Medium" },
    { operation: "Operation F", trainingHours: 50, riskLevel: "High" },
  ];

  // Color coding based on risk level
  const getColor = (riskLevel) => {
    switch (riskLevel) {
      case "Low":
        return "#82ca9d"; // Green
      case "Medium":
        return "#ffcc00"; // Yellow
      case "High":
        return "#ff6347"; // Red
      default:
        return "#82ca9d";
    }
  };

  return (
    <div>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Corruption Risk and Training Intensity
      </h3>

      <ResponsiveContainer width={650} height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="operation"
            label={{ value: "Operation", position: "bottom" }}
          />
          <YAxis
            label={{
              value: "Training Hours",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="trainingHours"
            stroke={getColor(data[0].riskLevel)} // Dynamically change color based on risk level
            dot={{ r: 6 }}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CorruptionRiskTrainingGraph;
