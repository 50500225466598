import React from "react";
import {
  MapContainer,
  TileLayer,
  ImageOverlay,
  Popup,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

const BeforeAfterMap = () => {
  // Define the map center and zoom level
  const mapCenter = [37.7749, -122.4194]; // Example: San Francisco coordinates
  const zoomLevel = 12;

  // Define the bounds for the before and after images (the area to be covered by the image)
  const imageBounds = [
    [37.77, -122.42], // bottom-left corner of the image
    [37.79, -122.4], // top-right corner of the image
  ];

  // URLs for the before and after images
  const beforeImageUrl = "path/to/before-image.png"; // Replace with your "before" image URL
  const afterImageUrl = "path/to/after-image.png"; // Replace with your "after" image URL

  return (
    <div style={{ width: 600, height: "400px" }}>
      <h3 style={{ margin: "25px", fontSize: "18px" }}>
        Before-and-After Restoration Comparison
      </h3>
      <MapContainer
        center={mapCenter}
        zoom={zoomLevel}
        style={{ width: "100%", height: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Before Image Overlay */}
        <ImageOverlay url={beforeImageUrl} bounds={imageBounds} opacity={0.5}>
          <Tooltip direction="top" offset={[0, -10]}>
            Before Restoration
          </Tooltip>
        </ImageOverlay>

        {/* After Image Overlay */}
        <ImageOverlay url={afterImageUrl} bounds={imageBounds} opacity={0.7}>
          <Tooltip direction="top" offset={[0, -10]}>
            After Restoration
          </Tooltip>
        </ImageOverlay>

        {/* Annotations or Labels */}
        <Popup position={[37.78, -122.41]}>
          <div>
            <strong>Restoration Effort</strong>
            <br />
            This area has been restored with better biodiversity and water
            quality.
          </div>
        </Popup>

        {/* Additional annotations can be added as needed */}
      </MapContainer>
    </div>
  );
};

export default BeforeAfterMap;
