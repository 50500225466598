import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

// Sample data: Recycled material composition by model
const data = [
  { model: "Model A", plastic: 300, metal: 150, glass: 80 },
  { model: "Model B", plastic: 200, metal: 180, glass: 100 },
  { model: "Model C", plastic: 250, metal: 120, glass: 60 },
  { model: "Model D", plastic: 220, metal: 160, glass: 90 },
];

const RecycledMaterialComposition = () => {
  return (
    <div>
      <h3 style={{ fontSize: "18px" }}>
        Recycled Material Composition by Model
      </h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />

          {/* X-Axis with custom label */}
          <XAxis dataKey="model">
            <Label
              value="Model"
              position="bottom"
              offset={0}
              style={{ fontSize: "14px", fontWeight: "bold" }}
            />
          </XAxis>

          {/* Y-Axis with custom label */}
          <YAxis>
            <Label
              value="Quantity of Recycled Material"
              angle={-90}
              position="left"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            />
          </YAxis>

          <Tooltip />
          <Legend />

          {/* Stacked Bars for Different Material Types */}
          <Bar dataKey="plastic" stackId="a" fill="#00C49F" barSize={60} />
          <Bar dataKey="metal" stackId="a" fill="#FF8042" barSize={60} />
          <Bar dataKey="glass" stackId="a" fill="#8884d8" barSize={60} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RecycledMaterialComposition;
