import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const productLifecycleData = [
  { stage: "Sourcing", value: 20 },
  { stage: "Manufacturing", value: 25 },
  { stage: "Distribution", value: 15 },
  { stage: "Use", value: 30 },
  { stage: "End-of-Life", value: 10 },
];

const ProductLifecycleDiagram = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return; // Prevent null ref errors
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 500;
    const height = 500;
    const margin = { top: 40, right: 40, bottom: 40, left: 40 };

    const radius = Math.min(width, height) / 2 - margin.top;

    // Create SVG element
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr(
        "transform",
        `translate(${width / 2},${height / 2})` // Center the chart
      );

    // Subtle color scale for the pie slices
    const color = d3
      .scaleOrdinal()
      .domain(productLifecycleData.map((d) => d.stage))
      .range([
        "#f7d7d7", // Soft red for Sourcing
        "#d7f7d7", // Light green for Manufacturing
        "#d7e7f7", // Light blue for Distribution
        "#f7f7d7", // Pale yellow for Use
        "#f0d7f7", // Light purple for End-of-Life
      ]);

    // Pie chart layout
    const pie = d3
      .pie()
      .value((d) => d.value)
      .sort(null);

    // Arc generator
    const arc = d3.arc().outerRadius(radius).innerRadius(0);

    // Label arc generator for positioning labels
    const labelArc = d3
      .arc()
      .outerRadius(radius - 40)
      .innerRadius(radius - 40);

    // Create the pie slices
    const slices = svg
      .selectAll("slice")
      .data(pie(productLifecycleData))
      .enter()
      .append("g")
      .attr("class", "slice");

    // Draw the slices
    slices
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.stage));

    // Add data labels (percentage or stage name)
    slices
      .append("text")
      .attr("transform", (d) => `translate(${labelArc.centroid(d)})`)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("fill", "#333") // Darker color for labels
      .text((d) => d.data.stage);

    // Optional: Add title
    svg
      .append("text")
      .attr("x", 0)
      .attr("y", -radius - 20)
      .style("text-anchor", "middle")
      .style("font-size", "16px")
      .style("fill", "#333")
      .text("Product Lifecycle");

    // Optional: Add tooltips
    slices.append("title").text((d) => `${d.data.stage}: ${d.data.value}%`);
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>Product Lifecycle Diagram</h3>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default ProductLifecycleDiagram;
