import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { department: "Sales", trainingHours: 120 },
  { department: "Engineering", trainingHours: 250 },
  { department: "HR", trainingHours: 80 },
  { department: "Marketing", trainingHours: 150 },
  { department: "Operations", trainingHours: 200 },
];

const TrainingHoursByDepartment = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return; // Prevent null ref errors
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 50, right: 150, bottom: 100, left: 60 };

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    // Create SVG element
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // X-scale
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.department))
      .range([0, chartWidth])
      .padding(0.4);

    // Y-scale
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.trainingHours)])
      .nice()
      .range([chartHeight, 0]);

    // Subtle color scale for departments
    const color = d3.scaleOrdinal().range([
      "#B0C4DE", // Light Steel Blue
      "#D3D3D3", // Light Gray
      "#A8D5BA", // Light Green
      "#F0E68C", // Khaki
      "#F4A300", // Soft Orange
    ]);

    // Draw bars
    g.selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.department))
      .attr("y", (d) => y(d.trainingHours))
      .attr("width", x.bandwidth())
      .attr("height", (d) => chartHeight - y(d.trainingHours))
      .attr("fill", (d) => color(d.department));

    // X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x));

    // Y-axis
    g.append("g").call(d3.axisLeft(y));

    // X-axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Department");

    // Y-axis label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Number of Training Hours");
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Training hours by Department
      </h3>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default TrainingHoursByDepartment;
