import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const SkillsSunburst = () => {
  const chartRef = useRef(null);

  // Modified data for skill improvement before and after training
  const data = {
    name: "Skill Improvement",
    children: [
      {
        name: "Skill A",
        children: [
          { name: "Pre-training", value: 50 },
          { name: "Post-training", value: 80 },
        ],
      },
      {
        name: "Skill B",
        children: [
          { name: "Pre-training", value: 60 },
          { name: "Post-training", value: 90 },
        ],
      },
      {
        name: "Skill C",
        children: [
          { name: "Pre-training", value: 40 },
          { name: "Post-training", value: 70 },
        ],
      },
    ],
  };

  useEffect(() => {
    if (!chartRef.current) return;
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 600;
    const radius = Math.min(width, height) / 2;

    // Clear any previous chart rendering
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    // Define color scheme (light blues for skills)
    const color = d3
      .scaleOrdinal()
      .domain([
        "Skill A",
        "Skill B",
        "Skill C",
        "Pre-training",
        "Post-training",
      ])
      .range([
        "#d9eaf7",
        "#b5d4f4",
        "#92bdf0",
        "#6fa6ec",
        "#4d92e8", // Light blues for skills and training stages
        "#f7b8a4",
        "#f59a7b",
        "#f37d53",
        "#f1602b", // Light corals for members
      ]);

    // Create partition layout
    const partition = d3
      .partition()
      .size([2 * Math.PI, radius])
      .padding(0.03);

    // Create arc generator
    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .innerRadius((d) => d.y0)
      .outerRadius((d) => d.y1);

    // Flatten the data into a hierarchy and calculate size for each segment
    const root = d3.hierarchy(data).sum((d) => d.value);

    partition(root);

    // Draw the sunburst arcs
    svg
      .selectAll("path")
      .data(root.descendants())
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.name))
      .attr("stroke", "#fff")
      .attr("stroke-width", 1)
      .attr("data-name", (d) => d.data.name)
      .attr("data-value", (d) => d.value)
      .on("mouseover", function (event, d) {
        const tooltip = d3.select("#tooltip");
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `<strong>${d.data.name}</strong><br/>Score: ${d.value}<br/>Skill: ${
              d.parent ? d.parent.data.name : "N/A"
            }`
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 10}px`);
      })
      .on("mouseout", function () {
        d3.select("#tooltip").transition().duration(200).style("opacity", 0);
      });

    // Append labels (for larger slices, or for important nodes)
    svg
      .selectAll("text")
      .data(root.descendants())
      .enter()
      .append("text")
      .attr("transform", (d) => `translate(${arc.centroid(d)})`)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#fff")
      .text((d) => (d.depth === 2 ? d.data.name : ""));

    // Tooltip div (for showing detailed info on hover)
    d3.select("body")
      .append("div")
      .attr("id", "tooltip")
      .style("position", "absolute")
      .style("background-color", "#f9f9f9")
      .style("border", "1px solid #ccc")
      .style("border-radius", "4px")
      .style("padding", "10px")
      .style("opacity", 0)
      .style("pointer-events", "none");
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>
        Skill Improvement Before and After Training
      </h3>

      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default SkillsSunburst;
