import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { BP8EQ2, BP2LQ3, BP2LQ5, Stationary_Combustion_, Fugitive_, Mobile_Combustion_, Purchase_Goods_Services_, GR2LQ1, GR2LQ2, GR2LQ3, Business_Travel_, HotelStay, Electricity, Employee_Category, Diversity_of_Employees } from "../../components/hardcoded/hardcodedforms";
import { Editor } from "primereact/editor";
import { hardcoded } from "../../components/hardcoded/hardcodedid";
import APIServices from "../../service/APIService";
import { DateTime } from "luxon";
import { StickyContainer, Sticky } from 'react-sticky';
import { DCFSubmissionReturn,DCFSubmission } from "../../constants/emailTemplate";
window.jQuery = $;
window.$ = $;

const DCFInputEntry = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [data, setData] = useState([])
    const [actual, setActual] = useState([])

    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const { id } = useParams();
    const params = useLocation()
    let std = params.state.draft === undefined ? params.state.standard : params.state.draft.standard
    const userList = useSelector(state => state.userlist.userList)
    const [ass, setAss] = useState([])
    // const hardcodeddcf = ['10', '11', '36', '15', '16', '188', '195', '196','245']
    useEffect(async () => {
        console.log(params)
console.log(DCFSubmission(1,2,3,4,5,0))
        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let site_url = API.LocationOne_UP(params.state.company_id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;

        APIServices.get(API.DCF_Edit(id)).then((res) => {
            // res.data.standard = params.state.standard


            if (params.state.id === undefined) {
                console.log(res.data)
                setData(res.data)
                setActual(JSON.stringify(res.data.data1))
            } else {
                console.log(params.state)
                setActual(JSON.stringify(params.state.response))
                res.data.data1 = params.state.response
                console.log(res)
                setData(res.data)

            }

        })



        APIServices.get(site_url).then((res) => {
            let site_list = []
            res.data.forEach((country) => {
                if (country.locationTwos !== undefined) {
                    country.locationTwos.forEach((city) => {
                        if (city.locationThrees !== undefined) {
                            city.locationThrees.forEach((site) => {

                                site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                            })
                        }
                    })
                }
            })
            setSiteList(site_list)
        })
        APIServices.get(API.AssignDCFUser_UP(selector.information.cid)).then((res) => {
            setAss(res.data)
        })
    }, [selector]);
    const checkUpdation = () => {
        let a = JSON.stringify(data.data1)
        let b = actual
        console.log(a === b)
        return a === b

    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }

    function getMinMaxDates(dateStrings) {
        // Parse date strings and sort them
        const dates = dateStrings.map(dateStr => DateTime.fromFormat(dateStr, 'MM-yyyy')).sort((a, b) => a - b);

    // Get min and max dates
    const minDate = dates[0].startOf('month').toJSDate();
    const maxDate = dates[dates.length - 1].endOf('month').toJSDate();

    return {
        minDate,
        maxDate
    };
    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        if (nan !== undefined) {
            if (isNaN(val)) {
                item.value = undefined
            } else {
                item.value = val;
            }

        } else {
            item.value = val;
        }

        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar className="col-5 fs-14 fw-4" value={item.value !== null ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText type='number' onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, parseFloat(e.target.value), 'as') }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}

                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <Editor className="text-area col-5" value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5" >
                        <div style={{
                            background: '#f8f9fa',
                            border: '1px solid #ced4da',
                            borderRadius: '6px 6px 0px 0px',
                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} className="fs-14 clr-navy" style={{
                                marginRight: 10,
                                padding: '5px',

                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',

                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>
                        {item.value != null && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()
            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }

    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }
                }
            } else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'file' && item.required) {
                if (item.value !== undefined && item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            } else if (item.type === 'file') {
                item['value'] = []
            }


        })


        return data_
    }

    const saveDCF = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.draft === undefined ? params.state.self ? 2 : 1 : params.state.draft.self ? 2: 1
            newObj['edit'] = 0
            newObj['form_type'] = 1
            newObj['data1'] = []
            newObj['reject'] = 0
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id
            let { value: return_remarks } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'remarks mandatory'
                    }
                }
            })

            if (return_remarks)

                if (params.state.id === undefined) {
                    newObj['created_on'] = dt
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 0, user_id: selector.id, created_on: dt }]
                    newObj['standard'] = std
                    newObj['assignmentId'] = params.state.assignmentId
                    newObj['self'] = params.state.self
                    if(params.state.self === true ){
                        newObj['reviewed_by'] = selector.id
                        newObj['reviewed_on'] = DateTime.utc()
                    }
                    let mail_remarks = ''
                    JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                        mail_remarks += `<p><strong>${ getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                    })
                    APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                        let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,0)
                       
                        let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                        if (approverIndex !== -1) {
                            let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                            if (approverIndex2 !== -1) {
                      
                                APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))

                            }
                        }
                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })

                } else {
                    newObj['modified_on'] = dt
                    if(params.state.draft.self === true ){
                        newObj['reviewed_by'] = selector.id
                        newObj['reviewed_on'] = DateTime.utc()
                    }
                    if (params.state.draft.return_remarks === null) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt }]

                    } else {
                        let lt = params.state.draft.return_remarks
                        lt.push({ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt })
                        newObj['return_remarks'] = lt
                    }
                    let mail_remarks = ''
                    JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                        mail_remarks += `<p><strong>${ getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                    })
                   
                    
                    
                    APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                        
                        let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,params.state.draft.rejected_on === null ? 0 : 1)
                       console.log(body)
                        let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                        if (approverIndex !== -1) {
                            let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                            if (approverIndex2 !== -1) {
                
                                APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))

                            }
                        }
                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {

                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })

                }

        }
        forceUpdate()

    }
    const checkResponse_ = () => {
        console.log(data)
        if (data.data1.length === 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Data set is Empty, requires minimum 1 record to submit/draft",
                showConfirmButton: false,
                timer: 1500,
            });
            return false
        } else {

            return true
        }
    }
    const checkResponse_263 = () => {
        let numbers = []
        data.data1.forEach((i) => {
            i.type2.forEach((j) => {
                j.data.forEach((l) => {
                    numbers.push(Object.values(l)[0])
                })

            })
        })
        console.log(numbers, data.data1)
        console.log(numbers.filter((i) => { return i === null }).length === 0)
        return numbers.filter((i) => { return i === null }).length === 0
    }
    const saveDCF_263 = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_263()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.draft === undefined ? params.state.self ? 2 : 1 : params.state.draft.self ? 2: 1
            newObj['form_type'] = 1
            newObj['edit'] = 0
            newObj['data1'] = []
            newObj['reject'] = 0
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id
            let { value: return_remarks } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'remarks mandatory'
                    }
                }
            })
            if (return_remarks) {
             
                if (params.state.id === undefined) {
                    newObj['standard'] = std
                    newObj['created_on'] = dt
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 0, user_id: selector.id, created_on: dt }]
                    newObj['assignmentId'] = params.state.assignmentId
                    newObj['self'] = params.state.self
                    if(params.state.self === true ){
                        newObj['reviewed_by'] = selector.id
                        newObj['reviewed_on'] = DateTime.utc()
                    }
                    let mail_remarks = ''
                    JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                        mail_remarks += `<p><strong>${ getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                    })
                    APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                        let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,0)
                        let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                        if (approverIndex !== -1) {
                            let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                            if (approverIndex2 !== -1) {
                                APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
        
                            }
                        }
                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })
                } else {
                    newObj['modified_on'] = dt
                    if(params.state.draft.self === true ){
                        newObj['reviewed_by'] = selector.id
                        newObj['reviewed_on'] = DateTime.utc()
                    }
                    if (params.state.draft.return_remarks === null) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt }]

                    } else {
                        let lt = params.state.draft.return_remarks
                        lt.push({ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt })
                        newObj['return_remarks'] = lt
                    }
                    let mail_remarks = ''
                    JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                        mail_remarks += `<p><strong>${ getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                    })
                    
                    
                    APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                        let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,params.state.draft.rejected_on === null ? 0 : 1)
                        let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                        if (approverIndex !== -1) {
                            let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                            if (approverIndex2 !== -1) {
                                APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
        
                            }
                        }
                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })

                }
            }
        }
        forceUpdate()

    }
    const draftDCF_HC_263 = () => {
        let newObj = {}
        if (checkResponse_263()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['edit'] = 0
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['form_type'] = 1
            newObj['reject'] = 0
            newObj['submitted_by'] = selector.id
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['created_on'] = DateTime.utc()
                newObj['assignmentId'] = params.state.assignmentId
                newObj['self'] = params.state.self
                
                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = DateTime.utc()


                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const checkResponse_262 = () => {
        let numbers = []
        data.data1.forEach((i) => {
            i.type2.forEach((j) => {
                j.type3.forEach((k) => {
                    k.data.forEach((l) => {
                        numbers.push(Object.values(l)[0])
                    })
                })
            })
        })
        console.log(numbers, data.data1)
        console.log(numbers.filter((i) => { return i === null }).length === 0)
        return numbers.filter((i) => { return i === null }).length === 0
    }
    const saveDCF_262 = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_262()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.draft === undefined ? params.state.self ? 2 : 1 : params.state.draft.self ? 2: 1
            newObj['form_type'] = 1
            newObj['edit'] = 0
            newObj['data1'] = []
            newObj['reject'] = 0
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id
            let { value: return_remarks } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'remarks mandatory'
                    }
                }
            })
            if (return_remarks) {
               
                if (params.state.id === undefined) {
                    newObj['standard'] = std
                    newObj['created_on'] = dt
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 0, user_id: selector.id, created_on: dt }]
                    newObj['assignmentId'] = params.state.assignmentId
                    newObj['self'] = params.state.self
                    if(params.state.self === true ){
                        newObj['reviewed_by'] = selector.id
                        newObj['reviewed_on'] = DateTime.utc()
                    }
                    let mail_remarks = ''
                    JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                        mail_remarks += `<p><strong>${ getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                    })
                    APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                        let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks, 0 )
                        let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                        if (approverIndex !== -1) {
                            let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                            if (approverIndex2 !== -1) {
                                APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
        
                            }
                        }
                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })
                } else {
                    newObj['modified_on'] = dt
                    if(params.state.draft.self === true ){
                        newObj['reviewed_by'] = selector.id
                        newObj['reviewed_on'] = DateTime.utc()
                    }
                    if (params.state.draft.return_remarks === null) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt }]

                    } else {
                        let lt = params.state.draft.return_remarks
                        lt.push({ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt })
                        newObj['return_remarks'] = lt
                    }
                    let mail_remarks = ''
                    JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                        mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                    })
                    
                    
                    APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                        let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,params.state.draft.rejected_on === null ? 0 : 1)
                        let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                        if (approverIndex !== -1) {
                            let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                            if (approverIndex2 !== -1) {
                                APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
        
                            }
                        }
                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })

                }
            }
        }
        forceUpdate()

    }
    const draftDCF_HC_262 = () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_262()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['edit'] = 0
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['form_type'] = 1
            newObj['reject'] = 0
            newObj['submitted_by'] = selector.id
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['created_on'] = dt
                newObj['assignmentId'] = params.state.assignmentId
                newObj['self'] = params.state.self
                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = DateTime.utc()


                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const saveDCF_ = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = data.data1

            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.draft === undefined ? params.state.self ? 2 : 1 : params.state.draft.self ? 2: 1
            newObj['form_type'] = 1
            newObj['edit'] = 0
            newObj['data1'] = []
            newObj['reject'] = 0
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id
            let { value: return_remarks } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'remarks mandatory'
                    }
                }
            })
            if (return_remarks) {
              
                if (params.state.id === undefined) {
                    newObj['created_on'] = dt
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 0, user_id: selector.id, created_on: dt }]
                    newObj['standard'] = std
                    newObj['assignmentId'] = params.state.assignmentId
                    newObj['self'] = params.state.self
                    if(params.state.self === true ){
                        newObj['reviewed_by'] = selector.id
                        newObj['reviewed_on'] = DateTime.utc()
                    }
                    let mail_remarks = ''
                    JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                        mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                    })
                    APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                        let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,0)
                        let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                        if (approverIndex !== -1) {
                            let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                            if (approverIndex2 !== -1) {
                                APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
        
                            }
                        }
                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })
                } else {
                    newObj['modified_on'] = dt
                    if(params.state.draft.self === true ){
                        newObj['reviewed_by'] = selector.id
                        newObj['reviewed_on'] = DateTime.utc()
                    }
                    if (params.state.draft.return_remarks === null) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt }]

                    } else {
                        let lt = params.state.draft.return_remarks
                        lt.push({ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt })
                        newObj['return_remarks'] = lt
                    }
                    let mail_remarks = ''
                    JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                        mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                    })
                    
                    
                    APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                        let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,params.state.draft.rejected_on === null ? 0 : 1)
                        let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                        if (approverIndex !== -1) {
                            let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                            if (approverIndex2 !== -1) {
                                APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
        
                            }
                        }
                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                navigate.goBack()
                            }
                        })

                    })

                }
            }
        }
        forceUpdate()

    }
    const draftDCF = () => {
        let newObj = {}
        if (checkResponse()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['edit'] = 0
            newObj['form_type'] = 1
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['reject'] = 0

            newObj['submitted_by'] = selector.id
            if (params.state.id === undefined) {
                newObj['created_on'] = DateTime.utc()
                newObj['standard'] = std
                newObj['assignmentId'] = params.state.assignmentId
                newObj['self'] = params.state.self
                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = DateTime.utc()

                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const draftDCF_HC = () => {
        let newObj = {}
        if (checkResponse_()) {
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = data.data1
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = 0
            newObj['edit'] = 0
            newObj['data1'] = []
            newObj['frequencycd'] = params.state.frequency
            newObj['form_type'] = 1
            newObj['reject'] = 0
            newObj['submitted_by'] = selector.id
            console.log(newObj)
            if (params.state.id === undefined) {
                newObj['created_on'] = DateTime.utc()
                newObj['standard'] = std
                newObj['assignmentId'] = params.state.assignmentId
                newObj['self'] = params.state.self
                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = DateTime.utc()


                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }

        }
        forceUpdate()

    }
    const emptyDCF = async () => {
        let dt = DateTime.utc()
        let { value: return_remarks } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to submit data as null/empty</div>`,
            input: 'textarea',
            inputValue: '',
            allowOutsideClick: false,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'remarks mandatory'
                }
            }
        })

        if (return_remarks) {
            let dt =DateTime.utc()
            let newObj = {}
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = makeEmpty()
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.draft === undefined ? params.state.self ? 2 : 1 : params.state.draft.self ? 2: 1
            newObj['edit'] = 1
            newObj['data1'] = []
            newObj['form_type'] = 1
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id
            if (params.state.id === undefined) {

                newObj['created_on'] = dt
                newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 0, user_id: selector.id, created_on: dt }]
                newObj['standard'] = std
                newObj['assignmentId'] = params.state.assignmentId
                newObj['self'] = params.state.self
                if(params.state.self === true ){
                    newObj['reviewed_by'] = selector.id
                    newObj['reviewed_on'] = DateTime.utc()
                }
                let mail_remarks = ''
                JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                    mail_remarks += `<p><strong>${ getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                })
                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks, 0 )
                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {
                            APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
    
                        }
                    }
                    Swal.fire({
                        title: "Data submitted as null report",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = dt
                if(params.state.draft.self === true ){
                    newObj['reviewed_by'] = selector.id
                    newObj['reviewed_on'] = DateTime.utc()
                }
                if (params.state.draft.return_remarks === null) {
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt }]

                } else {
                    let lt = params.state.draft.return_remarks
                    lt.push({ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt })
                    newObj['return_remarks'] = lt
                }
                let mail_remarks = ''
                JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                    mail_remarks += `<p><strong>${ getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                })
                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,params.state.draft.rejected_on === null ? 0 : 1)
                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {
                            APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
    
                        }
                    }
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }
        }
    }
    const getUser = (id) => {
        let user_name = 'Not Found'
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    }
    const emptyDCF_ = async () => {
        let { value: return_remarks } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to submit data as null/empty</div>`,
            input: 'textarea',
            inputValue: '',
            allowOutsideClick: false,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'remarks mandatory'
                }
            }
        })
        if (return_remarks) {
            let dt =DateTime.utc()
            let newObj = {}
            newObj['dcf'] = data.id
            newObj['site'] = params.state.site
            newObj['reporting_period'] = getRPLuxon(params.state.rp)
            newObj['response'] = []
            newObj['user_type'] = selector.role === 'clientuser' ? 1 : selector.role === 'clientadmin' ? 0 : 2
            newObj['type'] = params.state.draft === undefined ? params.state.self ? 2 : 1 : params.state.draft.self ? 2: 1
            newObj['edit'] = 1
            newObj['data1'] = []
            newObj['form_type'] = 1
            newObj['frequencycd'] = params.state.frequency
            newObj['submitted_by'] = selector.id

            if (params.state.id === undefined) {
                newObj['created_on'] = DateTime.utc()
                newObj['standard'] = std
                newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 0, user_id: selector.id, created_on: dt }]
                newObj['assignmentId'] = params.state.assignmentId
                newObj['self'] = params.state.self
                if(params.state.self === true ){
                    newObj['reviewed_by'] = selector.id
                    newObj['reviewed_on'] = DateTime.utc()
                }
                let mail_remarks = ''
                JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                    mail_remarks += `<p><strong>${ getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                })
                APIServices.post(API.DCF_Submit_UP(selector.clientId), newObj).then((res) => {
                    let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,0)
                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {
                            APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
    
                        }
                    }
                    Swal.fire({
                        title: "Data submitted as null report",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })
            } else {
                newObj['modified_on'] = DateTime.utc()
                if(params.state.draft.self === true ){
                    newObj['reviewed_by'] = selector.id
                    newObj['reviewed_on'] = DateTime.utc()
                }
                if (params.state.draft.return_remarks === null) {
                    newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt }]

                } else {
                    let lt = params.state.draft.return_remarks
                    lt.push({ remarks: return_remarks, user_type: 1, type: params.state.draft.rejected_on === null ? 1 : 2, user_id: selector.id, created_on: dt })
                    newObj['return_remarks'] = lt
                }
                let mail_remarks = ''
                JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                    mail_remarks += `<p><strong>${ getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                })

                APIServices.patch(API.DCF_Submission_Edit(params.state.id), newObj).then((res) => {
                    let body = DCFSubmission(selector.information.empname, data.title, params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name, params.state.rp, mail_remarks,params.state.draft.rejected_on === null ? 0 : 1)
                    let approverIndex = ass.findIndex((i) => { return i.site === params.state.site && i.dcfId === data.id && i.user_id === selector.id })
                    if (approverIndex !== -1) {
                        let approverIndex2 = userList.findIndex((i) => { return i.id === ass[approverIndex].approver_id })
                        if (approverIndex2 !== -1) {
                            APIServices.post(API.SubmissionMail, { email: [userList[approverIndex2].email], subject: 'DATA SUBMISSION - ESG PLATFORM', body: body }).then(res => console.log(res))
    
                        }
                    }
                    Swal.fire({
                        title: "Data successfully  saved as draft ",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            navigate.goBack()
                        }
                    })

                })

            }
        }
    }
    const checkHardcoded = () => {
        if (hardcoded.dcf.includes(id) || hardcoded.dcf2.includes(id)) {
            return true
        } else {
            return false
        }

    }
    return (
        <div className="grid font-lato" style={{ margin: 10 }}>
            <StickyContainer>
                <div className="col-12">
                    {(selector.id !== undefined && data.length !== 0) ?
                        <div >
                            <Sticky topOffset={0}>
                                {({ style, isSticky }) => (<div style={{ ...style, top: 98, background: isSticky && 'white', zIndex: 100 }}>
                                    <div className="fs-20 fw-7 clr-gray-900">
                                        <h4><span className="mr-2">{'DCF ' + id}</span> {data.title}</h4>
                                    </div>

                                    {/* {data.comments.trim().length !== 0 &&
                                        <div className="p-card fw-5 fs-16" style={{ padding: 10, marginBottom: 20 }}>
                                            <span style={{ fontWeight: 'bold' }}>Note :&nbsp;</span> {data.comments}
                                        </div>} */}
                                    <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }} >
                                        <div className="grid justify-content-between col-12">
                                            <div className="col-4 fs-16" style={{ flexDirection: 'row' }}>         <span className="clr-gray-3 fw-4">Reporting period:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.state.rp}   </span>  </div>
                                            <div className="col-5 fs-16 " style={{ flexDirection: 'row', textAlign: 'end' }}>          <span className="clr-gray-3 fw-4">Site:&nbsp;</span> <span className="clr-gray-900 fw-7"> {params.state !== undefined && sitelist.length !== 0 && sitelist.filter((i) => { return i.id === params.state.site })[0].name} </span>  </div>

                                            {/* <div className="col-3 fs-16" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Version:&nbsp;</span> <span className="clr-gray-900 fw-7">{moment(data.updated).local().format('DD MMM YYYY, hh:mm A')}</span>  </div> */}
                                        </div>

                                    </div>
                                </div>)}
                            </Sticky>

                            {(data.data1.length !== 0 && !checkHardcoded()) ?
                                <div>
                                    <div className="bg-white" style={{ borderRadius: 4 }}>

                                        <div style={{ padding: 24 }}>
                                            {data.data1.map((item, index) => {

                                                return renderItems(item, index)
                                            })

                                            }
                                        </div>
                                        <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                        <div className="grid" style={{ padding: 24 }} >
                                            <label className="col-5 fw-7 fs-16">Comments</label>
                                            {/* <Editor className="text-area col-5" value={data.remarks} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => { console.log(e.htmlValue); data.remarks = e.htmlValue; forceUpdate() }} /> */}
                                            <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                {params.state !== undefined && params.state.draft !== undefined && params.state.draft.return_remarks !== null &&
                                                    params.state.draft.return_remarks.map((cmnt) => {
                                                        return (
                                                            <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                <div className="col-5">
                                                                    <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                    <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    {cmnt.remarks}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div style={{ marginTop: 20 }}>
                                                {/* <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                        <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                            <Button label='Back' className="ml-4" onClick={() => { navigate.goBack() }} text ></Button>
                                            {params.state.future === undefined && <Button label='No Update' className="ml-4" outlined onClick={() => { emptyDCF() }} ></Button>}
                                            <Button label='Save as Draft' onClick={() => { draftDCF() }} className={"ml-4"}  ></Button>
                                            {params.state.future === undefined && <Button label='Save and Submit' onClick={() => { saveDCF() }} className="ml-4" ></Button>}

                                        </div>
                                    </div>

                                </div>
                                : hardcoded.dcf.includes(id) ?
                                    <div >
                                        <div className="bg-white" style={{ padding: 24 }}>
                                            <>
                                                {

                                                    (id === '10') ?
                                                        <div>
                                                            <Fugitive_ dates={ getMinMaxDates(getRPLuxon(params.state.rp))} standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div>

                                                        :
                                                        (id === '11') ?
                                                            <div>
                                                                <Stationary_Combustion_  dates={ getMinMaxDates(getRPLuxon(params.state.rp))}  standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div>
                                                            :
                                                            (id === '36') ?
                                                                <div>
                                                                    < Business_Travel_ dates={ getMinMaxDates(getRPLuxon(params.state.rp))}  standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                </div>

                                                                :
                                                                (id === '15') ?
                                                                    <div>
                                                                        <Mobile_Combustion_ dates={ getMinMaxDates(getRPLuxon(params.state.rp))}  standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                    </div>

                                                                    :
                                                                    (id === '16') ?
                                                                        <div>
                                                                            <Purchase_Goods_Services_  dates={ getMinMaxDates(getRPLuxon(params.state.rp))}  standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                        </div>
                                                                        : id === '188' ?
                                                                            <div>
                                                                                <BP8EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                            </div>
                                                                            : id === '195' ?
                                                                                <div>
                                                                                    <BP2LQ3 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                </div>
                                                                                : id === '196' ?
                                                                                    <div>
                                                                                        <BP2LQ5 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                    </div>
                                                                                    : id === '245' ?
                                                                                        <div>
                                                                                            <GR2LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                        </div>
                                                                                        : id === '246' ?
                                                                                            <div>
                                                                                                <GR2LQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                            </div>
                                                                                            : id === '247' ?
                                                                                                <div>
                                                                                                    <GR2LQ3 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                </div>
                                                                                                : id === '254' ?
                                                                                                    <div>
                                                                                                        <HotelStay dates={ getMinMaxDates(getRPLuxon(params.state.rp))}  standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                    </div>
                                                                                                    : id === '257' ?
                                                                                                        <div>
                                                                                                            <Electricity  standard={std} data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                        </div> : 'Contact Admin'



                                                }</>
                                            <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                            <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                <label className="col-5 fw-7 fs-16">Comments</label>
                                                <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                    {params.state !== undefined && params.state.draft !== undefined && params.state.draft.return_remarks !== null &&
                                                        params.state.draft.return_remarks.map((cmnt) => {
                                                            return (
                                                                <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                    <div className="col-5">
                                                                        <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                        <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        {cmnt.remarks}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div style={{ padding: 24 }}>
                                                    {/* <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                </div>
                                            </div>

                                        </div>

                                        <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                            <div className="flex justify-content-end " style={{ background: 'white', padding: 10 }}>
                                                <Button className="ml-4" label='Back' onClick={() => { navigate.goBack() }} text></Button>
                                                {params.state.future === undefined && <Button className="ml-4" outlined label='No Update' onClick={() => { emptyDCF_() }} ></Button>}

                                                <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF_HC() }} ></Button>
                                                {params.state.future === undefined && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF_() }}></Button>}



                                            </div>
                                        </div>

                                    </div>
                                    : id === '262' ?
                                        <div>
                                            <div className="bg-white" style={{ padding: 24 }}>
                                                <Employee_Category data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                        {params.state !== undefined && params.state.draft !== undefined && params.state.draft.return_remarks !== null &&
                                                            params.state.draft.return_remarks.map((cmnt) => {
                                                                return (
                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                        <div className="col-5">
                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {cmnt.remarks}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div >
                                                    {/* <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                </div>
                                            </div>
                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                    <Button className="ml-4" label='Back' onClick={() => { navigate.goBack() }} text></Button>
                                                    {params.state.future === undefined && <Button className="ml-4" outlined label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                    <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF_HC_262() }} ></Button>
                                                    {params.state.future === undefined && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF_262() }}></Button>}

                                                </div>
                                            </div>
                                        </div> : id === '263' &&
                                        <div>
                                            <div className="bg-white" style={{ padding: 24 }}>
                                                <Diversity_of_Employees data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                        {params.state !== undefined && params.state.draft !== undefined && params.state.draft.return_remarks !== null &&
                                                            params.state.draft.return_remarks.map((cmnt) => {
                                                                return (
                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                        <div className="col-5">
                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {cmnt.remarks}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div >
                                                    {/* <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                </div>
                                            </div>
                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                                    <Button className="ml-4" label='Back' onClick={() => { navigate.goBack() }} text></Button>
                                                    {params.state.future === undefined && <Button className="ml-4" outlined label='No Update' onClick={() => { emptyDCF_() }} ></Button>}
                                                    <Button label='Save as Draft' className="ml-4" onClick={() => { draftDCF_HC_263() }} ></Button>
                                                    {params.state.future === undefined && <Button className="ml-4" label='Save & Submit' onClick={() => { saveDCF_263() }}></Button>}

                                                </div>
                                            </div>
                                        </div>
                            }




                        </div>
                        :
                        <div className="col-12 card">Form not found</div>
                        // <div className="col-12 card">You have no rights to access this page </div>

                    }
                </div>
            </StickyContainer>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DCFInputEntry, comparisonFn);
