import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { department: "HR", workerRepresentatives: 5, managementRepresentatives: 3 },
  {
    department: "Finance",
    workerRepresentatives: 4,
    managementRepresentatives: 6,
  },
  { department: "IT", workerRepresentatives: 6, managementRepresentatives: 2 },
  {
    department: "Operations",
    workerRepresentatives: 7,
    managementRepresentatives: 4,
  },
];

const RepresentationInHealthAndSafetyCommitteesChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 420;
    const margin = { top: 50, right: 30, bottom: 120, left: 80 };

    // Clear any previous chart
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // X Scale
    const x0 = d3
      .scaleBand()
      .domain(data.map((d) => d.department))
      .range([0, chartWidth])
      .padding(0.3);

    const x1 = d3
      .scaleBand()
      .domain(["workerRepresentatives", "managementRepresentatives"])
      .range([0, x0.bandwidth()])
      .padding(0.05);

    // Y Scale
    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, (d) =>
          Math.max(d.workerRepresentatives, d.managementRepresentatives)
        ),
      ])
      .nice()
      .range([chartHeight, 0]);

    // Color Scale (subtle colors)
    const color = d3
      .scaleOrdinal()
      .domain(["workerRepresentatives", "managementRepresentatives"])
      .range(["#a8d0e6", "#ffb3ba"]); // Subtle blue and pink colors

    // Draw Axes
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x0).tickSize(0))
      .selectAll("text")
      .style("text-anchor", "middle");

    g.append("g").call(
      d3
        .axisLeft(y)
        .ticks(6) // Optional: Adjust the number of ticks
        .tickFormat(d3.format("d")) // Ensures whole numbers
    );

    // Add Bars for Worker Representatives
    g.selectAll(".workerRepresentatives")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "workerRepresentatives")
      .attr("x", (d) => x0(d.department)) // Position of the bar
      .attr("y", (d) => y(d.workerRepresentatives))
      .attr("height", (d) => chartHeight - y(d.workerRepresentatives))
      .attr("width", x1.bandwidth())
      .attr("fill", color("workerRepresentatives"))
      .append("title")
      .text((d) => `Worker Representatives: ${d.workerRepresentatives}`);

    // Add Bars for Management Representatives
    g.selectAll(".managementRepresentatives")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "managementRepresentatives")
      .attr("x", (d) => x0(d.department) + x1.bandwidth()) // Shift the bar to the right
      .attr("y", (d) => y(d.managementRepresentatives))
      .attr("height", (d) => chartHeight - y(d.managementRepresentatives))
      .attr("width", x1.bandwidth())
      .attr("fill", color("managementRepresentatives"))
      .append("title")
      .text(
        (d) => `Management Representatives: ${d.managementRepresentatives}`
      );

    // Add X-Axis Label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .style("text-anchor", "middle")
      .text("Department or Location")
      .style("font-size", "14px");

    // Add Y-Axis Label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -60)
      .style("text-anchor", "middle")
      .text("Number of Representatives")
      .style("font-size", "14px");

    // Add Title

    // Add Legend - Adjusted position (move down)
    const legend = svg
      .append("g")
      .attr(
        "transform",
        `translate(${width / 2 - 80},${height - margin.bottom + 50})`
      ); // Lowered the legend

    legend
      .append("rect")
      .attr("x", -10)
      .attr("y", 0)
      .attr("width", 15)
      .attr("height", 15)
      .attr("fill", color("workerRepresentatives"));

    legend
      .append("text")
      .attr("x", 10)
      .attr("y", 12)
      .style("font-size", "12px")
      .text("Worker Representatives");

    legend
      .append("rect")
      .attr("x", 150)
      .attr("y", 0)
      .attr("width", 15)
      .attr("height", 15)
      .attr("fill", color("managementRepresentatives"));

    legend
      .append("text")
      .attr("x", 170)
      .attr("y", 12)
      .style("font-size", "12px")
      .text("Management Representatives");
  };

  return (
    <>
      <h3 style={{ margin: "25px", fontSize: "18px" }}>
        Representation in Health and Safety Committees
      </h3>
      <div
        ref={chartRef}
        style={{ display: "flex", justifyContent: "center" }}
      />
    </>
  );
};

export default RepresentationInHealthAndSafetyCommitteesChart;
