import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { category: "Grade 1", value: 50 },
  { category: "Grade 2", value: 70 },
  { category: "Grade 3", value: 30 },
  { category: "Grade 4", value: 90 },
  { category: "Grade 5", value: 40 },
  { category: "Grade 6", value: 20 },
];

const DiversityOfEmployeeByJobGrades = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 50, right: 100, bottom: 100, left: 60 };

    // Remove any existing SVG for re-rendering
    d3.select(chartRef.current).select("svg").remove();

    // Create SVG canvas
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // X and Y scales
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.category))
      .range([0, chartWidth])
      .padding(0.4);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value) + 10]) // Add some padding to the top
      .range([chartHeight, 0]);

    // Colors for bars
    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d.category))
      .range([
        "#cfe2f3", // Light blue
        "#d9e3f5", // Soft lavender
        "#f8d7da", // Soft red
        "#c6d9f1", // Soft blue
        "#fce2b3", // Light yellow
        "#fbe4d5", // Soft peach
      ]);

    // Add X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end");

    // Add Y-axis
    g.append("g").call(d3.axisLeft(y));

    // Add X-axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 50)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Job Grades");

    // Add Y-axis label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Number of Employees");

    // Draw bars
    g.selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.category))
      .attr("y", (d) => y(d.value))
      .attr("width", x.bandwidth())
      .attr("height", (d) => chartHeight - y(d.value))
      .attr("fill", (d) => color(d.category))
      .append("title") // Tooltip
      .text((d) => `${d.category}: ${d.value}`);

    // Add legend
    const legend = svg
      .append("g")
      .attr(
        "transform",
        `translate(${chartWidth + margin.left + 20},${margin.top})`
      );

    data.forEach((d, i) => {
      legend
        .append("rect")
        .attr("x", -50)
        .attr("y", i * 25 + 100)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", color(d.category));

      legend
        .append("text")
        .attr("x", 20 - 50)
        .attr("y", i * 25 + 12 + 100)
        .style("font-size", "12px")
        .text(d.category);
    });
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Diversity of Employees by Job Grades
      </h3>

      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default DiversityOfEmployeeByJobGrades;
