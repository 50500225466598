import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { interpolateRdYlGn } from "d3-scale-chromatic"; // Red-Yellow-Green color scale

const dummyData = {
  products: ["Product A", "Product B", "Product C", "Product D", "Product E"],
  standards: [
    "Standard 1",
    "Standard 2",
    "Standard 3",
    "Standard 4",
    "Standard 5",
  ],
  compliance: {
    "Standard 1": [1, 0, 2, 1, 0],
    "Standard 2": [2, 1, 0, 2, 1],
    "Standard 3": [0, 2, 1, 0, 2],
    "Standard 4": [1, 0, 2, 1, 2],
    "Standard 5": [2, 1, 0, 2, 1],
  },
};

const ProductComplianceMatrix = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderMatrix();
  }, []);

  const renderMatrix = () => {
    const margin = { top: 50, right: 50, bottom: 50, left: 150 };
    const width = 650 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const complianceLevels = Object.values(dummyData.compliance).flat();

    // Define color scale
    const colorScale = d3
      .scaleSequential(interpolateRdYlGn) // Red (low) -> Yellow -> Green (high)
      .domain([0, 2]); // 0 = Non-compliant, 1 = Partial, 2 = Fully compliant

    const products = dummyData.products;
    const standards = dummyData.standards;

    // Define scales
    const xScale = d3
      .scaleBand()
      .domain(products)
      .range([0, width])
      .padding(0.05);

    const yScale = d3
      .scaleBand()
      .domain(standards)
      .range([0, height])
      .padding(0.05);

    // Add X axis (Products)
    svg
      .append("g")
      .selectAll(".x-axis")
      .data(products)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y", height + 15)
      .attr("text-anchor", "middle")
      .text((d) => d)
      .style("font-size", "12px");

    // Add Y axis (Standards)
    svg
      .append("g")
      .selectAll(".y-axis")
      .data(standards)
      .enter()
      .append("text")
      .attr("x", -10)
      .attr("y", (d) => yScale(d) + yScale.bandwidth() / 2)
      .attr("text-anchor", "end")
      .text((d) => d)
      .style("font-size", "12px")
      .style("dominant-baseline", "middle");

    // Create matrix cells
    svg
      .selectAll("rect")
      .data(
        standards.flatMap((standard, i) =>
          products.map((product, j) => ({
            standard,
            product,
            value: dummyData.compliance[standard][j],
          }))
        )
      )
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.product))
      .attr("y", (d) => yScale(d.standard))
      .attr("width", xScale.bandwidth())
      .attr("height", yScale.bandwidth())
      .style("fill", (d) => colorScale(d.value))
      .on("mouseover", (event, d) => {
        d3.select(event.currentTarget).style("opacity", 0.7);
        svg
          .append("text")
          .attr("x", xScale(d.product) + xScale.bandwidth() / 2)
          .attr("y", yScale(d.standard) + yScale.bandwidth() / 2)
          .attr("text-anchor", "middle")
          .attr("dy", ".35em")
          .style("font-size", "14px")
          .style("fill", "black")
          .text(
            d.value === 0
              ? "Non-compliant"
              : d.value === 1
              ? "Partial"
              : "Fully"
          );
      })
      .on("mouseout", () => {
        d3.selectAll(".hover-text").remove();
        d3.selectAll("rect").style("opacity", 1);
      });

    // Add legend
  };

  return (
    <div>
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "25px",
        }}
      >
        Product Regulatory Compliance Matrix
      </div>
      <div ref={chartRef} style={{ textAlign: "center" }} />

      {/* Legend Description */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <span style={{ fontSize: "14px" }}>
          Color scale: <b>Green</b> - Fully compliant, <b>Yellow</b> - Partial,{" "}
          <b>Red</b> - Non-compliant.
        </span>
      </div>
    </div>
  );
};

export default ProductComplianceMatrix;
