import React from "react";
import GaugeChart from "react-gauge-chart";

const dummyData = {
  totalEmployees: 1000, // Total number of employees
  trainedEmployees: 750, // Number of employees trained
};

const DataPrivacyTrainingGauge = () => {
  const progress =
    (dummyData.trainedEmployees / dummyData.totalEmployees) * 100;

  return (
    <div style={{ fontFamily: "Lato", padding: "10px" }}>
      <div style={{ marginTop: "30px" }}>
        <h3 style={{ margin: "25px", fontSize: "18px" }}>
          Data Privacy Training Progress
        </h3>
      </div>

      {/* Gauge Chart */}
      <div
        style={{
          textAlign: "center",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <GaugeChart
          id="data-privacy-training-gauge"
          nrOfLevels={30}
          arcsLength={[0.3, 0.3, 0.4]} // Define color zones
          colors={["#e74c3c", "#f39c12", "#16a085"]} // Red, Yellow, Green
          percent={progress / 100} // Progress as a percentage (0-1 scale)
          needleColor="#000000"
          arcPadding={0.02}
          textColor="#000000"
          style={{ width: 650, height: "360px" }} // Adjusted size
        />

        {/* Label for the gauge */}
        <div
          style={{
            marginTop: "10px",
            fontSize: "16px",
            fontWeight: "bold",
            color:
              progress >= 70
                ? "#16a085"
                : progress >= 40
                ? "#f39c12"
                : "#e74c3c", // Dynamic color
          }}
        >
          Percentage of Employees Trained: {progress.toFixed(2)}%
        </div>
      </div>
    </div>
  );
};

export default DataPrivacyTrainingGauge;
