import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const treeData = {
  name: "Health and Safety Committee",
  children: [
    {
      name: "Management",
      children: [{ name: "Manager 1" }, { name: "Manager 2" }],
    },
    {
      name: "Worker Representatives",
      children: [
        {
          name: "Department A",
          children: [{ name: "Worker 1" }, { name: "Worker 2" }],
        },
        {
          name: "Department B",
          children: [{ name: "Worker 3" }, { name: "Worker 4" }],
        },
      ],
    },
  ],
};

const ParticipationStructureTree = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderTree();
  }, []);

  const renderTree = () => {
    const margin = { top: 30, right: 90, bottom: 30, left: 90 };
    const width = 800 - margin.left - margin.right;
    const height = 650;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create the tree layout
    const tree = d3.tree().size([height, width]);

    // Assign the data to the tree layout
    const root = d3.hierarchy(treeData);
    tree(root);

    // Create the links (edges)
    svg
      .selectAll(".link")
      .data(root.links())
      .enter()
      .append("path")
      .attr("class", "link")
      .attr(
        "d",
        (d) =>
          `M${d.source.x},${d.source.y}C${d.source.x},${
            (d.source.y + d.target.y) / 2
          } ${d.target.x},${(d.source.y + d.target.y) / 2} ${d.target.x},${
            d.target.y
          }`
      )
      .style("fill", "none")
      .style("stroke", "#ccc")
      .style("stroke-width", "2px");

    // Create the nodes
    const node = svg
      .selectAll(".node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d) => `translate(${d.x},${d.y})`);

    // Add circles to the nodes
    node
      .append("circle")
      .attr("r", 10)
      .style(
        "fill",
        (d) =>
          d.depth === 0
            ? "#4caf50" // Root node (committee)
            : d.depth === 1
            ? "#2196f3" // Management
            : "#f44336" // Worker representatives
      );

    // Add text labels to the nodes
    node
      .append("text")
      .attr("dy", ".35em")
      .attr("y", (d) => (d.depth === 0 ? -20 : 20))
      .attr("x", (d) => (d.depth === 0 ? 0 : d.children ? -15 : 15))
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .text((d) => d.data.name);
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Organisational Structure and Representation
      </h3>

      <div
        ref={chartRef}
        style={{ display: "flex", justifyContent: "center" }}
      />
    </>
  );
};

export default ParticipationStructureTree;
