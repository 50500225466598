import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

// Sample data for Scope 1, Scope 2, and Scope 3 emissions
const data = [
  {
    name: "Scope 1",
    actual: 20,
    target: 25,
  },
  {
    name: "Scope 2",
    actual: 10,
    target: 20,
  },
  {
    name: "Scope 3",
    actual: 15,
    target: 25,
  },
];

const ScopeRadarChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderRadarChart();
  }, []);

  const renderRadarChart = () => {
    const width = 600;
    const height = 300;
    const radius = Math.min(width, height) / 2;
    const colorActual = "#e69f00"; // Color for actual emissions
    const colorTarget = "#56b4e9"; // Color for target emissions

    // Clear any existing chart
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Create scale for the radius based on the maximum value
    const maxValue = d3.max(data, (d) => Math.max(d.actual, d.target));

    // Create scale for each axis (radial)
    const angleSlice = (Math.PI * 2) / data.length;

    const radiusScale = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([0, radius]);

    // Draw the axes
    svg
      .selectAll(".axis")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "axis")
      .each(function (d, i) {
        const axis = d3.select(this);
        const angle = i * angleSlice;
        axis
          .append("line")
          .attr("x1", 0)
          .attr("y1", 0)
          .attr("x2", radiusScale(maxValue) * Math.cos(angle))
          .attr("y2", radiusScale(maxValue) * Math.sin(angle))
          .attr("stroke", "#ccc")
          .attr("stroke-width", "1");

        axis
          .append("text")
          .attr("class", "legend")
          .attr("x", (radius + 10) * Math.cos(angle))
          .attr("y", (radius + 10) * Math.sin(angle))
          .attr("font-size", "12px")
          .attr("fill", "#555")
          .text(d.name);
      });

    // Draw the actual and target values for each axis
    const dataPointsActual = data.map((d) => radiusScale(d.actual));
    const dataPointsTarget = data.map((d) => radiusScale(d.target));

    const line = d3
      .lineRadial()
      .angle((d, i) => i * angleSlice)
      .radius((d) => d);

    svg
      .append("path")
      .data([dataPointsActual])
      .attr("class", "area")
      .attr("d", line)
      .attr("fill", colorActual)
      .attr("fill-opacity", 0.6);

    svg
      .append("path")
      .data([dataPointsTarget])
      .attr("class", "area")
      .attr("d", line)
      .attr("fill", colorTarget)
      .attr("fill-opacity", 0.6);

    // Add the tooltips for each data point
    svg
      .selectAll(".point")
      .data(dataPointsActual)
      .enter()
      .append("circle")
      .attr("class", "point")
      .attr("cx", (d, i) => radiusScale(d) * Math.cos(i * angleSlice))
      .attr("cy", (d, i) => radiusScale(d) * Math.sin(i * angleSlice))
      .attr("r", 5)
      .attr("fill", colorActual)
      .on("mouseover", function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 8)
          .attr("fill", "#ff7f0e");
      })
      .on("mouseout", function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 5)
          .attr("fill", colorActual);
      });

    svg
      .selectAll(".target-point")
      .data(dataPointsTarget)
      .enter()
      .append("circle")
      .attr("class", "target-point")
      .attr("cx", (d, i) => radiusScale(d) * Math.cos(i * angleSlice))
      .attr("cy", (d, i) => radiusScale(d) * Math.sin(i * angleSlice))
      .attr("r", 5)
      .attr("fill", colorTarget)
      .on("mouseover", function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 8)
          .attr("fill", "#1f77b4");
      })
      .on("mouseout", function (event, d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("r", 5)
          .attr("fill", colorTarget);
      });
  };

  return (
    <div>
      <div style={{ margin: "25px" }}>
        <h3 style={{ fontSize: "18px" }}>Emissions Performance vs. Targets</h3>
        <div>
          Comparing the performance vs. Targets of Scope 1, Scope 2, and Scope 3
          emissions.
        </div>
      </div>
      <div ref={chartRef}></div>
    </div>
  );
};

export default ScopeRadarChart;
