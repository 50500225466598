import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { category: "Under 30", value: 80 },
  { category: "30-50", value: 150 },
  { category: "Over 50", value: 40 },
];

const AgeDiversityFemaleChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return; // Prevent null ref errors
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 300;
    const height = 300;
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };

    const radius = Math.min(width, height) / 2 - margin.top;

    // Create SVG element
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr(
        "transform",
        `translate(${width / 2},${height / 2})` // Center the chart
      );

    // Color scale for the pie slices
    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d.category))
      .range(["#FFCC99", "#66B2FF", "#99FF66"]); // Light orange, blue, green

    // Pie chart layout
    const pie = d3
      .pie()
      .value((d) => d.value)
      .sort(null);

    // Arc generator
    const arc = d3.arc().outerRadius(radius).innerRadius(0);

    // Label arc generator for positioning labels
    const labelArc = d3
      .arc()
      .outerRadius(radius - 40)
      .innerRadius(radius - 40);

    // Create the pie slices
    const slices = svg
      .selectAll("slice")
      .data(pie(data))
      .enter()
      .append("g")
      .attr("class", "slice");

    // Draw the slices
    slices
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.category));

    // Add data labels (percentage or number of employees)
    slices
      .append("text")
      .attr("transform", (d) => `translate(${labelArc.centroid(d)})`)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("fill", "#fff")
      .text(
        (d) =>
          `${d.data.value} (${(
            (d.data.value / d3.sum(data, (d) => d.value)) *
            100
          ).toFixed(1)}%)`
      );

    // Optional: Add title
    svg
      .append("text")
      .attr("x", 0)
      .attr("y", -radius - 20)
      .style("text-anchor", "middle")
      .style("font-size", "16px")
      .text("Age Diversity (Female)");
  };

  return (
    <>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default AgeDiversityFemaleChart;
