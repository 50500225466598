import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from "recharts";

const AntiCorruptionTrainingGraph = () => {
  // Sample data for the graph
  const data = [
    { year: 2023, percentage: 35 },
    { year: 2024, percentage: 85 },
  ];

  return (
    <div>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Employees Trained in Anti-Corruption
      </h3>

      <ResponsiveContainer width={650} height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="year"
            label={{ value: "Year", position: "bottom", offset: 0 }} // X-Axis label
          />
          <YAxis
            label={{
              value: "Percentage of Employees Trained in Anti-Corruption",
              angle: -90, // Angle to rotate the Y-Axis label
              position: "insideLeft", // Position of the label
            }} // Y-Axis label
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="percentage"
            stroke="#82ca9d"
            activeDot={{ r: 8 }}
            fill="#82ca9d"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AntiCorruptionTrainingGraph;
