import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

const ZLDCompliance = ({ complianceLevel = 0 }) => {
  // Default complianceLevel to 0 if undefined
  const getColor = (level) => {
    if (level === 100) return "success"; // MUI color name for 100% compliance
    if (level >= 50) return "warning"; // MUI color name for partial compliance
    return "error"; // MUI color name for non-compliance
  };

  const getComplianceLabel = (level) => {
    if (level === 100) return "100% Compliance";
    if (level >= 50) return "Partial Compliance";
    return "Non-Compliance";
  };

  return (
    <>
      {" "}
      <h3 style={{ margin: "25px", fontSize: "18px" }}>
        Percentage of Operational units with Zero Liquid Discharge Facility
      </h3>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            value={complianceLevel}
            size={400} // Increase size here
            thickness={3} // Increase thickness for a bolder ring
            color={getColor(complianceLevel)} // Using MUI color names
          />
          <Box
            position="absolute"
            top="35%"
            left="25%"
            transform="translate(-50%, -50%)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <h1 style={{ fontSize: "88px" }}>{`${complianceLevel}%`}</h1>
          </Box>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {getComplianceLabel(complianceLevel)}
        </Typography>
      </Box>
    </>
  );
};

export default ZLDCompliance;
