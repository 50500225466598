import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import {
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register necessary Chart.js components
Chart.register(BarElement, Title, Tooltip, CategoryScale, LinearScale);

const WasteManagementHierarchy = () => {
  const chartRef = useRef();

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    // Data for the Pyramid Chart
    const data = {
      labels: [
        "Recycling",
        "Energy Recovery",
        "Waste Disposal",
        "Waste Reduction",
      ], // Waste Categories
      datasets: [
        {
          label: "Waste Quantity",
          data: [150, 100, 50, 30], // Quantities of Waste for each category
          backgroundColor: [
            "rgba(75, 192, 192, 0.7)", // Green for waste reduction
            "rgba(255, 159, 64, 0.7)", // Orange for energy recovery
            "rgba(255, 99, 132, 0.7)", // Red for waste disposal
            "rgba(54, 162, 235, 0.7)", // Blue for recycling
          ],
          borderColor: [
            "rgba(75, 192, 192, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    // Chart Configuration
    const config = {
      type: "bar", // Horizontal Bar Chart
      data: data,
      options: {
        responsive: true,
        indexAxis: "y", // Make the chart horizontal
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.label}: ${context.raw} units`; // Display the quantity of waste on hover
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Quantity of Waste", // X-Axis Label
            },
            beginAtZero: true,
          },
          y: {
            title: {
              display: true,
              text: "Waste Category", // Y-Axis Label
            },
          },
        },
        layout: {
          padding: {
            top: 30,
            bottom: 30,
          },
        },
      },
    };

    // Create the Pyramid-like Bar Chart
    new Chart(ctx, config);

    // Cleanup chart on component unmount
    return () => {
      if (ctx) {
        const chartInstance = Chart.getChart(ctx);
        if (chartInstance) {
          chartInstance.destroy();
        }
      }
    };
  }, []);

  return (
    <div>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Waste Management Hierarchy
      </h3>

      <div style={{ width: 600 }}>
        {" "}
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default WasteManagementHierarchy;
