import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const WorkforceRepresentationDonutChart = () => {
  // Static data for the workforce representation
  const data = [
    { name: "On Safety Committees", value: 75 }, // 75% on safety committees
    { name: "Not on Safety Committees", value: 25 }, // 25% not on safety committees
  ];

  // Color scheme for the segments
  const COLORS = ["#16a085", "#e74c3c"]; // Green for "On Safety Committees", Red for "Not on Safety Committees"

  return (
    <div style={{ fontFamily: "Lato" }}>
      <h3 style={{ fontSize: "18px", marginLeft: "25px", marginTop: "25px" }}>
        Workforce Representation in Safety Committees
      </h3>
      <div style={{ fontSize: "14px", marginLeft: "25px" }}>
        Displaying the distribution of employees in safety committees vs. those
        not participating in the committees.
      </div>

      {/* Donut Chart */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%" // Centering the chart
            cy="50%"
            outerRadius={150} // Outer radius for the donut chart
            innerRadius={120} // Inner radius to create the donut effect
            fill="#8884d8"
            paddingAngle={5}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>

      {/* Percentage of employees on Safety Committees */}
      <div
        style={{ textAlign: "center", fontSize: "16px", fontWeight: "bold" }}
      >
        On Safety Committees: {data[0].value}%
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "bold",
          marginBottom: "25px",
        }}
      >
        Not on Safety Committees: {data[1].value}%
      </div>
    </div>
  );
};

export default WorkforceRepresentationDonutChart;
