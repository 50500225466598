import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { Box, Typography } from "@mui/material";

const complaintData = [
  { month: "January", filed: 50, resolved: 40, pending: 10 },
  { month: "February", filed: 60, resolved: 50, pending: 10 },
  { month: "March", filed: 70, resolved: 60, pending: 15 },
  { month: "April", filed: 80, resolved: 70, pending: 25 },
  { month: "May", filed: 90, resolved: 80, pending: 20 },
];

const ComplaintResolutionStatusChart = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding="20px"
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        width="100%"
        marginBottom="20px"
      >
        <Typography variant="h6">Complaint Resolution Status</Typography>
      </Box>
      <ResponsiveContainer width={600} height={400}>
        <BarChart data={complaintData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month">
            <Label
              value="Time Period (Months)"
              offset={0}
              position="insideBottom"
            />
          </XAxis>
          <YAxis>
            <Label
              value="Number of Complaints"
              angle={-90}
              position="insideLeft"
            />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar
            dataKey="filed"
            stackId="a"
            fill="#d6e1f2" // Light blue color for filed complaints
            barSize={60}
          />
          <Bar
            dataKey="resolved"
            stackId="a"
            fill="#90e0ef" // Light blue for resolved complaints
            barSize={60}
          />
          <Bar
            dataKey="pending"
            stackId="a"
            fill="#ffbc42" // Light orange for pending complaints
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ComplaintResolutionStatusChart;
