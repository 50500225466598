import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  {
    committee: "Audit",
    male_below30: 10,
    male_30_50: 20,
    male_above50: 15,
    female_below30: 5,
    female_30_50: 25,
    female_above50: 10,
  },
  {
    committee: "Governance",
    male_below30: 15,
    male_30_50: 30,
    male_above50: 10,
    female_below30: 10,
    female_30_50: 20,
    female_above50: 15,
  },
];

const DiversityOfBoardMembers = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 50, right: 150, bottom: 100, left: 60 };

    // Remove any existing SVG for re-rendering
    d3.select(chartRef.current).select("svg").remove();

    // Create SVG canvas
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Keys for stacking
    const keys = [
      "male_below30",
      "male_30_50",
      "male_above50",
      "female_below30",
      "female_30_50",
      "female_above50",
    ];

    // Stack data
    const stackedData = d3.stack().keys(keys)(data);

    // X and Y scales
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.committee))
      .range([0, chartWidth])
      .padding(0.4);

    const y = d3
      .scaleLinear()
      .domain([0, 100]) // Percentages
      .range([chartHeight, 0]);

    // Colors for bars
    const color = d3.scaleOrdinal().domain(keys).range([
      "#cfe2f3", // Light blue
      "#6fa8dc", // Blue
      "#073763", // Dark blue
      "#f4cccc", // Light pink
      "#e06666", // Pink
      "#990000", // Dark red
    ]);

    // Fixed bar width
    const barWidth = 60;

    // Add X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x));

    // Add Y-axis
    g.append("g").call(
      d3
        .axisLeft(y)
        .ticks(5)
        .tickFormat((d) => `${d}%`)
    );

    // Add X-axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Committees");

    // Add Y-axis label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Percentage of Members");

    // Draw stacked bars
    g.selectAll("g.stack")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.data.committee) + (x.bandwidth() - barWidth) / 2) // Center bars
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", barWidth)
      .append("title")
      .text((d, i) => {
        const key = keys[i % keys.length];
        return `${key.replace(/_/g, " ")}: ${d[1] - d[0]}%`;
      });

    // Add legend
    const legend = svg
      .append("g")
      .attr(
        "transform",
        `translate(${chartWidth + margin.left + 20},${margin.top})`
      );

    keys.forEach((key, i) => {
      legend
        .append("rect")
        .attr("x", 0 - 50)
        .attr("y", i * 25 + 100)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", color(key));

      legend
        .append("text")
        .attr("x", 20 - 50)
        .attr("y", i * 25 + 12 + 100)
        .style("font-size", "12px")
        .text(key.replace(/_/g, " "));
    });
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Diversity of Board Members in Different Committees
      </h3>

      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default DiversityOfBoardMembers;
