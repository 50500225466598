import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

// Sample data for energy consumption
const data = [
  {
    period: "2023",
    renewable: 55,
    nonRenewable: 45,
  },
  {
    period: "2024",
    renewable: 60,
    nonRenewable: 40,
  },
];

const RenewableNonRenewable = () => {
  return (
    <div style={{ width: 600, height: 400 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Energy Consumption Breakdown
      </h3>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }} // Increased bottom margin for the X-axis label
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period">
            <Label
              value="Time Period"
              position="left"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </XAxis>
          <YAxis>
            <Label
              value="Energy Consumption (%)"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </YAxis>
          <Tooltip />
          <Legend
            verticalAlign="bottom" // Move the legend to the bottom
            height={30}
          />
          {/* Renewable Energy Bar */}
          <Bar
            dataKey="renewable"
            stackId="a"
            barSize={60}
            fill="#82ca9d" // Green for renewable energy
          />
          {/* Non-Renewable Energy Bar */}
          <Bar
            dataKey="nonRenewable"
            stackId="a"
            barSize={60}
            fill="#8884d8" // Blue for non-renewable energy
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RenewableNonRenewable;
