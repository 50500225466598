import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";

const ESGGoalProgressGauge = () => {
  const [progress, setProgress] = useState(0); // Dynamic progress value (0-100%)

  useEffect(() => {
    // Simulating live updates
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 0 : prev + Math.random() * 10)); // Increment progress randomly
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  return (
    <div style={{ fontFamily: "Lato", padding: "10px" }}>
      <div style={{ marginTop: "30px" }}>
        <h3 style={{ margin: "0", fontSize: "18px" }}>
          ESG Goal Progress: Renewable Energy Target
        </h3>
      </div>

      {/* Gauge Chart */}
      <div
        style={{
          textAlign: "center",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <GaugeChart
          id="esg-goal-progress-gauge"
          nrOfLevels={30}
          arcsLength={[0.3, 0.3, 0.4]} // Define color zones
          colors={["#e74c3c", "#f39c12", "#16a085"]} // Red, Yellow, Green
          percent={progress / 100} // Progress as a percentage (0-1 scale)
          needleColor="#000000"
          arcPadding={0.02}
          textColor="#000000"
          style={{ width: "800px", height: "350px" }} // Adjusted size
        />

        {/* Label for the gauge */}
        <div
          style={{
            marginTop: "10px",
            fontSize: "16px",
            fontWeight: "bold",
            color:
              progress >= 70
                ? "#16a085"
                : progress >= 40
                ? "#f39c12"
                : "#e74c3c", // Dynamic color
          }}
        >
          Progress Towards Goal: {progress.toFixed(2)}%
        </div>
      </div>
    </div>
  );
};

export default ESGGoalProgressGauge;
