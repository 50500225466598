import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { Box, Typography } from "@mui/material";

const correctiveActionData = [
  { month: "January", initiated: 5, inProgress: 8, completed: 3 },
  { month: "February", initiated: 7, inProgress: 6, completed: 5 },
  { month: "March", initiated: 4, inProgress: 9, completed: 8 },
  { month: "April", initiated: 6, inProgress: 4, completed: 7 },
  { month: "May", initiated: 8, inProgress: 5, completed: 10 },
];

const CorrectiveActionProgressChart = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding="20px"
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        width="100%"
        marginBottom="20px"
      >
        <Typography variant="h6">Corrective Action Progress</Typography>
      </Box>
      <ResponsiveContainer width="80%" height={400}>
        <BarChart data={correctiveActionData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month">
            <Label
              value="Time Period (Months)"
              offset={0}
              position="insideBottom"
            />
          </XAxis>
          <YAxis>
            <Label
              value="Number of Actions"
              angle={-90}
              position="insideLeft"
            />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar
            dataKey="initiated"
            stackId="a"
            fill="#e0a8a1" // Subtle red color
            barSize={60}
          />
          <Bar
            dataKey="inProgress"
            stackId="a"
            fill="#f7d189" // Subtle yellow color
            barSize={60}
          />
          <Bar
            dataKey="completed"
            stackId="a"
            fill="#9ac6b8" // Subtle green color
            barSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CorrectiveActionProgressChart;
