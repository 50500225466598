import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { sankey as d3Sankey, sankeyLinkHorizontal } from "d3-sankey";

const PreventiveActionFlow = () => {
  const svgRef = useRef();

  useEffect(() => {
    const width = 1000;
    const height = 400;

    const data = {
      nodes: [
        { id: "Identified Issues" },
        { id: "Risk Assessment" },
        { id: "Action Plan Developed" },
        { id: "Action Implemented" },
        { id: "Effectiveness Evaluated" },
        { id: "Closed" },
      ],
      links: [
        { source: "Identified Issues", target: "Risk Assessment", value: 120 },
        {
          source: "Risk Assessment",
          target: "Action Plan Developed",
          value: 100,
        },
        {
          source: "Action Plan Developed",
          target: "Action Implemented",
          value: 90,
        },
        {
          source: "Action Implemented",
          target: "Effectiveness Evaluated",
          value: 80,
        },
        { source: "Effectiveness Evaluated", target: "Closed", value: 70 },
      ],
    };

    // Remove existing content
    d3.select(svgRef.current).selectAll("*").remove();

    // Create the SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    const sankey = d3Sankey()
      .nodeId((d) => d.id)
      .nodeWidth(10)
      .nodePadding(50)
      .extent([
        [0, 0],
        [width, height],
      ]);

    const { nodes, links } = sankey({
      nodes: data.nodes.map((d) => ({ ...d })),
      links: data.links.map((d) => ({ ...d })),
    });

    // Draw links (flows)
    svg
      .append("g")
      .selectAll("path")
      .data(links)
      .join("path")
      .attr("d", sankeyLinkHorizontal())
      .attr("stroke", "gray")
      .attr("fill", "none")
      .attr("stroke-width", (d) => Math.max(1, d.width))
      .attr("opacity", 0.8)
      .append("title")
      .text((d) => `${d.source.id} → ${d.target.id}: ${d.value}`);

    // Draw nodes
    svg
      .append("g")
      .selectAll("rect")
      .data(nodes)
      .join("rect")
      .attr("x", (d) => d.x0)
      .attr("y", (d) => d.y0)
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0)
      .attr("fill", "lightblue")
      .append("title")
      .text((d) => `${d.id}: ${d.value}`);

    // Add node labels
    svg
      .append("g")
      .selectAll("text")
      .data(nodes)
      .join("text")
      .attr("x", (d) => (d.x0 + d.x1) / 2)
      .attr("y", (d) => (d.y0 + d.y1) / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .text((d) => d.id);
  }, []);

  return (
    <div>
      <h3 style={{ fontSize: "18px" }}>Preventive Action Flow</h3>
      <div style={{ textAlign: "center" }}>
        <svg ref={svgRef} />
      </div>
    </div>
  );
};

export default PreventiveActionFlow;
