import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const TrainingParticipationTrend = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return; // Prevent null ref errors
    renderChart();
  }, []);

  const renderChart = () => {
    const data = [
      { year: 2018, participationRate: 60 },
      { year: 2019, participationRate: 70 },
      { year: 2020, participationRate: 80 },
      { year: 2021, participationRate: 75 },
      { year: 2022, participationRate: 85 },
    ];

    const width = 600;
    const height = 400;
    const margin = { top: 40, right: 20, bottom: 60, left: 80 };

    // Create SVG container
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    // Define scales
    const x = d3
      .scaleLinear()
      .domain([d3.min(data, (d) => d.year), d3.max(data, (d) => d.year)])
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleLinear()
      .domain([0, 100]) // Assuming participation rate is between 0 and 100
      .range([height - margin.bottom, margin.top]);

    // Line generator
    const line = d3
      .line()
      .x((d) => x(d.year))
      .y((d) => y(d.participationRate))
      .curve(d3.curveMonotoneX); // Smooth curve

    // Add line to SVG
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#007bff") // Blue line
      .attr("stroke-width", 2)
      .attr("d", line);

    // Add X-axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).ticks(data.length).tickFormat(d3.format("d")))
      .selectAll("text")
      .attr("text-anchor", "middle")
      .style("font-size", "12px");

    // Add Y-axis
    svg
      .append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).ticks(10))
      .selectAll("text")
      .style("font-size", "12px");

    // Add X-axis label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 15)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Year");

    // Add Y-axis label
    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", 20)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .attr("transform", "rotate(-90)")
      .text("Participation Rate (%)");
  };

  return (
    <div>
      <h3 style={{ fontSize: "18px" }}>Training Participation Trend</h3>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </div>
  );
};

export default TrainingParticipationTrend;
