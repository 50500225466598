import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Circle, Marker, Popup } from "react-leaflet";
import { Checkbox, FormControlLabel } from "@mui/material";
import * as d3 from "d3";
import { LatLngExpression } from "leaflet";

// Data for heatmap (you can update this with your actual data points)
const data = [
  { lat: 51.505, lng: -0.09, impact: 100 }, // high impact
  { lat: 51.515, lng: -0.1, impact: 80 },
  { lat: 51.525, lng: -0.11, impact: 50 },
  { lat: 51.535, lng: -0.12, impact: 30 }, // moderate impact
  { lat: 51.545, lng: -0.13, impact: 10 }, // low impact
];

const colors = {
  high: "#f44336", // red for high impact
  moderate: "#FF9800", // orange for moderate impact
  low: "#4CAF50", // green for low impact
};

const EcosystemHeatmap = () => {
  // Color scale for impact
  const getColorForImpact = (impact) => {
    if (impact >= 80) return colors.high; // High impact (red)
    if (impact >= 50) return colors.moderate; // Moderate impact (orange)
    return colors.low; // Low impact (green)
  };

  return (
    <div>
      <div style={{ marginTop: "30px" }}></div>

      {/* Map Container with heatmap */}
      <div style={{ height: "400px", marginTop: "20px" }}>
        <MapContainer
          center={[51.505, -0.09]} // Initial map center
          zoom={13}
          style={{ width: "100%", height: "100%" }}
        >
          {/* Base Map (Satellite imagery) */}
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          {/* Data points as Circles */}
          {data.map((point, index) => (
            <Circle
              key={index}
              center={[point.lat, point.lng]}
              radius={30} // Adjust radius based on impact
              fillColor={getColorForImpact(point.impact)}
              color={getColorForImpact(point.impact)}
              fillOpacity={0.6}
            >
              <Popup>
                <strong>Impact Level: </strong>
                {point.impact}
              </Popup>
            </Circle>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default EcosystemHeatmap;
