import React, { useEffect, useRef, useState } from "react";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import $ from "jquery";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import FileSaver, { saveAs } from "file-saver";
import { InputTextarea } from "primereact/inputtextarea";
import { TabMenu } from "primereact/tabmenu";

import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";
import { Checkbox } from "primereact/checkbox";
import { DateTime } from "luxon";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { FilterMatchMode, FilterService } from 'primereact/api';
import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getReportingFiscalYearByReportingperiod } from "../../components/BGHF/helper";



let activeRowData = {}

const NewQualitativeApprover = () => {
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);

    const navigate = useHistory()
    const sumdtref = useRef(null)
    const [dcfentityass, setDcfEntityAss] = useState([])
    const [dfentityass, setDfEntityAss] = useState([])
    const [srfentityass, setSrfEntityAss] = useState([])
    const [rfresponse, setRFResponse] = useState([])
    const [approveddata, setApprovedData] = useState([])
    const [load, setLoading] = useState(true)
    const [rawrf, setRawRF] = useState([])
    const [rawsrf, setRawSRF] = useState([])
    const [rfentityass, setRFEntityAss] = useState([])
    const [minDate, setMinDate] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const forceUpdate = useForceUpdate()
    const [dcfentityuserass, setDcfEntityUserAss] = useState([])
    const [summarydata, setSummaryData] = useState([])
    const [overallfilter, setOverallFilter] = useState({ ent: { value: null, matchMode: 'in' }, frequency: { value: null, matchMode: 'in' }, formtype: { value: null, matchMode: 'in' }, form: { value: null, matchMode: 'in' } })
    const [dfentityuserass, setDfEntityUserAss] = useState([])
    const [srfentityuserass, setSrfEntityUserAss] = useState([])
    const [frequencyMonths, setFrequencyMonths] = useState([])
    const [dflist, setDfList] = useState([])
    const [stdlist, setStdList] = useState([])
    const [stdlistBk, setStdListBK] = useState([])
    const [selectedloclist, setSelectedLocList] = useState({ tier1: [], tier2: [], tier3: [] })
    const [assigneddcflist, setAssignedDcfList] = useState([])
    const [assigneddflist, setAssignedDfList] = useState([])
    const [requiredList, setRequiredList] = useState([])
    const [requiredListBK, setRequiredListBK] = useState([])
    const [requiredList2, setRequiredList2] = useState([])
    const [assFramework, setAssFramework] = useState([])
    const [selectedFramework2, setSelectedFramework2] = useState([])
    const [assFramework2, setAssFramework2] = useState([])
    const [selectedFramework, setSelectedFramework] = useState([])
    const [historydata, setHistoryData] = useState([])
    const [historydialog, setHistoryDialog] = useState(false)

    const [requiredList2BK, setRequiredList2BK] = useState([])
    const [selecteddcfentity, setSelectedDcfEntity] = useState({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], title: null, dcfId: null })
    const [selectedsrfentity, setSelectedSrfEntity] = useState({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], title: null, srfId: null })
    const [selecteddfentity, setSelectedDfEntity] = useState({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], title: null, dfId: null, type: null })
    const [dcfentityuserobj, setDcfEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], standard: null })
    const [allentityuserobj, setAllEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], standard: null })
    const [srfentityuserobj, setSrfEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [] })
    const [dfentityuserobj, setDfEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], type: null })
    const [dcfentitydialog, setDcfEntityDialog] = useState(false)
    const [srfentitydialog, setSrfEntityDialog] = useState(false)
    const [dfentitydialog, setDfEntityDialog] = useState(false)
    const [dcfentityuserdialog, setDcfEntityUserDialog] = useState(false)
    const [dfentityuserdialog, setDfEntityUserDialog] = useState(false)
    const [srfentityuserdialog, setSrfEntityUserDialog] = useState(false)
    const [allentityuserdialog, setAllEntityUserDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [dcfentityviewdialog, setDcfEntityViewDialog] = useState(false)
    const [srfentityviewdialog, setSrfEntityViewDialog] = useState(false)
    const [dfenitityviewdialog, setDfEnitityViewDialog] = useState(false)
    const userList_ = useSelector(state => state.userlist.userList)
    const supplierList_ = useSelector(state => state.userlist.supplierList)

    const [rawsitelist, setRawSitelist] = useState([])
    const [roleasslist, setRoleAssList] = useState([])

    const [userList, setUserList] = useState([]);
    const [summaryyear, setSummaryYear] = useState(0);
    const [supplierList, setSupplierList] = useState([]);
    const [approverList, setApproverList] = useState([]);
    const [reviewerList, setReviewerList] = useState([]);
    const [yearoptions, setYearOptions] = useState([])
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }]
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);

    const entryStartDate = DateTime.fromISO(admin_data.information.startdate, { zone: 'utc' }).toJSDate()
    const [label1, label2, label3] = useSelector((state) => state.user.tierLabel);
    const [qlyearoption, setQlYearOption] = useState(getFiscalYearsFromStartDate(admin_data.information.startdate, fymonth))

    useEffect(() => {
        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let yrOptions = getFiscalYearsFromStartDate(admin_data.information.startdate)
        console.log(yrOptions)
        setYearOptions(yrOptions)
        const promise0 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))

        const promise1 = APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise2 = APIServices.get(API.RF)
        const promise3 = APIServices.get(API.QL_Submit_UP(admin_data.id))
        const promise4 = APIServices.get(API.Report_Name_Twos)
        const promise5 = APIServices.get(API.Report_Name_Twos)
        const promise6 = APIServices.get(API.DF_Entity_UP(admin_data.id))
        const promise7 = APIServices.get(API.QL_Approval_UP(admin_data.id))



        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7]).then((values) => {

            setApprovedData(values[7].data)
            let df_list = []
            let assignedDf = values[6].data.filter(i => i.approver_ids && i.approver_ids.includes(login_data.id))

            // DF

            let required_rf = [], optional_rf = []
            setRawRF(values[2].data)

            setRFResponse(values[3].data)
            // DF

            const shapedCategory = values[1].data.map(item => {
                if (item.newTopics) {
                    item.newTopics = item.newTopics.filter(topics =>
                        topics.newMetrics && topics.newMetrics.length > 0
                    );
                }
                return item;
            }).filter(item => item.newTopics && item.newTopics.length > 0)
            console.log(shapedCategory)




            if (values[0].data.length !== 0) {
                // DF
                let topic_ids = values[0].data[0].topic_ids


                shapedCategory.forEach((cat) => {
                    if (cat.newTopics) {
                        cat.newTopics.forEach((topic) => {
                            if (topic_ids.includes(topic.id) && (topic.tag === null || parseFloat(topic.tag) === admin_data.id) && topic.newMetrics) {

                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[2].data.map(i => i.id).includes(metric.data1[0].rf) && (metric.tag === null || (parseFloat(metric.tag) === admin_data.id))) {


                                        let rf_index = values[2].data.findIndex(i => i.id === metric.data1[0].rf)
                                        if (rf_index !== -1 && (values[2].data[rf_index].tags === null || values[2].data[rf_index].tags.includes(admin_data.id))) {
                                            required_rf.push({ dfId: values[2].data[rf_index].id, rf_title: values[2].data[rf_index].title, cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                            df_list.push(values[2].data[rf_index])
                                        }


                                    }
                                })
                            } else if (!topic_ids.includes(topic.id) && topic.newMetrics && (topic.tag === null || parseFloat(topic.tag) === admin_data.id)) {
                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[2].data.map(i => i.id).includes(metric.data1[0].rf) && (metric.tag === null || parseFloat(metric.tag) === admin_data.id)) {
                                        let rf_index = values[2].data.findIndex(i => i.id === metric.data1[0].rf)
                                        if (rf_index !== -1 && (values[2].data[rf_index].tags === null || values[2].data[rf_index].tags.includes(admin_data.id))) {
                                            optional_rf.push({ dfId: values[2].data[rf_index].id, rf_title: values[2].data[rf_index].title, cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                            df_list.push(values[2].data[rf_index])
                                        }
                                    }
                                })
                            }
                        }
                        )
                    }
                })
                setDfList(df_list)


                setAssignedDfList([...required_rf, ...optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) })].filter(i => assignedDf.map(x => x.dfId).includes(i.dfId)))
                console.log(assignedDf, optional_rf, required_rf, [...required_rf, ...optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) })].filter(i => assignedDf.map(x => x.dfId).includes(i.dfId)))


                setAssFramework(values[5].data.filter((i) => { return admin_data.information.report.includes(i.id) }))
                setAssFramework2(values[5].data)
                setRequiredList2(optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) }))
                setRequiredList2BK(optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) }))
                setRequiredList(required_rf)
                setRequiredListBK(required_rf)
            }
            setLoading(false)
        })
    }, [])



    const statusTemplate = (rowData) => {


        let text = 'Not Responded'
        let data = rowData.data1[0]

        let index = rfresponse.filter((i) => { return i.dfId === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && i.type === 1 && getReportingFiscalYearByReportingperiod(rowData.reporting_period) === getReportingFiscalYearByReportingperiod(i.reporting_period) })
        let index1 = approveddata.find((i) => { return i.dfId === data.rf && (i.type === 1 || i.type === 0) && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && (fymonth === 1 ? parseFloat(rowData.year) : (parseFloat(rowData.year.split('-')[0]) + 1)) === i.reporting_year })
        console.log(index,index1, rowData)
        if (!index1 && index.length) {
            text = index.map(i => i.reporting_period.length).reduce((a, b) => { return a + b }, 0) + ' response received from ' + Object.keys(groupByMultipleKeys(index, ['locationId', 'level'])).length + ' entities'
        } else if (index1 && index1.type === 1 ) {
            text = 'Approved & Locked'
        } else if (index1 && index1.type === 0 && !index1.edit ) {
            text = 'Drafted'
        }else if (index1 && index1.type === 0 && index1.edit ) {
            text = 'Unlocked For Submission'
        }

        return (
            <>
                {text}
            </>
        )
    }
    const historyTemplate = (rowData) => {
        let text = true
        let data = rowData.data1[0]
        let mergeData = []
        console.log(rowData)
        let index = rfresponse.findLastIndex((i) => { return i.dfId === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && JSON.stringify(rowData.reporting_period) === JSON.stringify(i.reporting_period) })

        console.log(index, rfresponse)
        if (index !== -1) {
            text = false
            mergeData = rfresponse.filter((i) => { return i.dfId === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && JSON.stringify(rowData.reporting_period) === JSON.stringify(i.reporting_period) })
            mergeData.forEach((i) => {
                i.top_title = rowData.top_title
                i.cat_title = rowData.cat_title
            })
            console.log(mergeData)
        }
        return (
            <>
                {text ?
                    <span>NA</span> :
                    <a className="cur-pointer" onClick={() => { setHistoryData(mergeData); activeRowData = rowData; setHistoryDialog(true) }}>View History</a>
                }

            </>
        )
    }
    const getUser = (id) => {
        let user_name = 'Not Found'
        if (id === admin_data.id) {
            return 'Enterprise Admin'
        }
        let index = userList_.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList_[index].information.empname
        }
        return user_name
    }
    const submitterTemplate = (rowData) => {

        let text = 'NA'
        let data = rowData.data1[0]

        let index = rfresponse.findLastIndex((i) => { return i.dfId === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && JSON.stringify(rowData.reporting_period) === JSON.stringify(i.reporting_period) })

        if (index !== -1) {
            if (rfresponse[index].type >= 1) {
                text = getUser(rfresponse[index].reporter_modified_by)

            }

        }
        return (
            <>
                {text}
            </>
        )
    }
    const rrTemplate = (rowData) => {


        let data = rowData.data1[0], oldData = null, id = 0, show = true
        let index1 = approveddata.findLastIndex((i) => { return i.dfId === data.rf && i.type === 1 && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && (fymonth === 1 ? parseFloat(rowData.year) : (parseFloat(rowData.year.split('-')[0]) + 1)) === i.reporting_year })
        let index = rfresponse.findLastIndex((i) => { return i.dfId === data.rf && i.type === 1 && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && JSON.stringify(rowData.reporting_period) === JSON.stringify(i.reporting_period) })
        if (index !== -1) {
            oldData = rfresponse[index]
        }


        return (
            < >
                <a style={{ cursor: index1 === -1 ? 'pointer' : 'default', textDecoration: index1 === -1 ? 'underline' : 'none' }} onClick={() => { if (oldData === null) { window.open(window.location.origin + '/qualitative_consolidate').sessionStorage.setItem('dfconsol', JSON.stringify({ data: rowData })) } }}>       {rowData.data1[0].title} </a>

            </>
        )
    }

    const titleTemplate = (rowData) => {
        let oldData = []
        return (
            < >
                <Tooltip className="tag-tooltip" target={".tags" + rowData.id} position={'top'} autoHide={true}> {rowData.overallTags.map((i, j) => {
                    if (i.length !== 0) {
                        return (
                            <>
                                <label style={{ color: 'black', display: 'flex' }}> {
                                    j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                }
                                </label>
                                {
                                    i.map((tag, k) => {

                                        return (
                                            <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                        )

                                    })
                                }
                                <div style={{ marginBottom: 10 }} />
                            </>
                        )
                    }
                })} </Tooltip>
                <div style={{ alignItems: 'center' }} >{rowData.title} <i className={"material-icons " + "tags" + rowData.id} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

            </>
        )
    }

    function groupByMultipleKeys(array, keys) {
        return array.reduce((result, item) => {
            // Create a composite key by joining the values of the specified keys with a hyphen
            const compositeKey = keys.map(key => item[key]).join('-');

            // If the group doesn't exist yet, create it
            if (!result[compositeKey]) {
                result[compositeKey] = [];
            }

            // Push the current object into the correct group
            result[compositeKey].push(item);

            return result;
        }, {});
    }
    function countOccurrences(data) {
        let count = 0;
        console.log(data)
        for (const key in data) {
            if (Array.isArray(data[key])) {
                let len = data[key].filter(item => {
                    return item.assId !== undefined
                }).length
                if (len !== 0) {
                    count++
                }
            }
        }

        return count;
    }




    const renderTable = (val) => {
        console.log(val)
        setSelectedFramework(val)
        let filtered = []
        if (val.length) {
            filtered = requiredListBK.filter(obj => {
                return obj.overallTags.some(tagsArray => {
                    return tagsArray.some(tag => {
                        return val.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                    });
                });
            });
        } else {
            filtered = requiredListBK
        }
        console.log(filtered.length)
        // let filtered = requiredListBK.filter((i) => { return (i.overallTags.some(array => array.some(item => item.includes(val))) || val === 'All') })
        setRequiredList(filtered)
    }
    const renderTable2 = (val) => {
        setSelectedFramework2(val)
        let filtered = []
        if (val.length) {
            filtered = requiredList2BK.filter(obj => {
                return obj.overallTags.some(tagsArray => {
                    return tagsArray.some(tag => {
                        return val.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                    });
                });
            });
        } else {
            filtered = requiredList2BK
        }
        // let filtered = requiredList2BK.filter((i) => { return (i.overallTags.some(array => array.some(item => item.includes(val))) || val === 'All') })
        setRequiredList2(filtered)
    }
    function getFiscalYearsFromStartDate(start_date) {
        console.log(start_date);
        const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).plus({ months: 1 });
        const currentDate = DateTime.now();

        let startFiscalYear, currentFiscalYear;
        const fiscalYears = [];

        if (fymonth === 1) {
            // When fiscal month is January, it's a single year
            startFiscalYear = startDate.year;
            currentFiscalYear = currentDate.year;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                fiscalYears.push({ name: year, label: `${year}` });
            }
        } else {
            // Normal fiscal year spanning two calendar years
            startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
            currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                const label = `${year}-${(year + 1).toString().slice(-2)}`;
                fiscalYears.push({ name: year + 1, label });
            }

            // Include the current fiscal year only if the current month is before the fiscal year start month
            if (currentDate.month < fymonth) {
                fiscalYears.pop();
            }
        }

        return fiscalYears;
    }

    const getFiscalYearRange = (year, fymonth) => {
        let startDate, endDate;
        console.log(fymonth)

        if (fymonth === 1) {
            startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
        } else {
            startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
        }

        return { startDate, endDate };
    };
    function getFiscalYearMonths(year, fymonth) {
        let months = [];
        const startYear = fymonth === 1 ? year : year - 1;

        for (let i = 0; i < 12; i++) {
            // Calculate the month number (1-12)
            let month = (fymonth + i - 1) % 12 + 1;

            // Calculate the correct year based on the fiscal month
            let currentYear = month >= fymonth ? startYear : startYear + 1;

            // Format the month as 'MM-YYYY'
            months.push(`${month.toString().padStart(2, '0')}-${currentYear}`);
        }

        return months;
    }

    return (
        <div>
            {(admin_data.id !== 289) ? <>
                <div style={{
                    fontSize: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: '600', marginBottom: 10
                }} >Qualitative Approval(s)</div>

                <div>
                    <div style={{ marginBottom: 10 }}>
                        <label
                            style={{
                                marginRight: 10,
                            }}
                        >
                            Filter by Standards / Frameworks / Disclosures
                        </label>
                        <MultiSelect display="chip" style={{ width: 300 }} value={selectedFramework} onChange={(e) => renderTable(e.value)} options={assFramework} optionLabel="title" optionValue="title"
                            filter={true} placeholder="Select" panelClassName={'hidefilter'} />

                    </div>
                </div>
                <DataTable className="ql-tvs-approval" loading={load} value={assigneddflist.flatMap(d =>
                    qlyearoption.map(year => ({
                        ...d,
                        year: year.label, reporting_period: getFiscalYearMonths(year.name, fymonth)
                    }))
                )} scrollable paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} >
                    <Column field='cat_title' header='Category' />
                    <Column field='top_title' header='Topic' />
                    <Column field='title' body={titleTemplate} header='Aspect' />
                    <Column field='year' header='Year' />
                    <Column header='Requirement' body={rrTemplate} />

                    <Column body={statusTemplate} header='Status' />


                </DataTable>


                <Dialog
                    visible={historydialog}
                    style={{
                        width: "30%",
                    }}
                    header={"Response History"}
                    modal
                    className="p-fluid"

                    onHide={() => { setHistoryDialog(false) }}
                >
                    <div>
                        {historydata.sort((a, b) => { return moment(b.last_modified_on).toDate() - moment(a.last_modified_on).toDate() }).map((i) => {
                            return (
                                <div style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', padding: 5, borderRadius: 10, margin: 5, boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px' }}>
                                    <text style={{ color: 'black', borderRadius: 10, padding: 5, fontStyle: 'italic', cursor: 'pointer' }} onClick={() => { window.open(window.location.origin + '/df_submission_preview/').sessionStorage.setItem('dfpreview', JSON.stringify({ id: i.dfId, params: { state: { data: activeRowData, oldData: i } } })) }}>{DateTime.fromISO(i.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd-MMM-yyyy HH:mm')} Hours by <span style={{ color: 'green' }}>{i.submitted_by === admin_data.id ? 'Admin' : userList_.findIndex((j) => { return j.id === i.submitted_by }) === -1 ? 'User Deleted' : userList_.find((j) => { return j.id === i.submitted_by }).information.empname}</span> </text>

                                </div>
                            )
                        })

                        }
                    </div>
                </Dialog>
            </> :
                < div className='flex justify-content-center'> You Have No Rights To Access This Page </div>
            }
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(NewQualitativeApprover, comparisonFn);