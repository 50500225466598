import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const TreeMap = () => {
  const graphRef = useRef(null);

  const [visibleScopes, setVisibleScopes] = useState({
    "Scope 1": true,
    "Scope 2": true,
    "Scope 3": true,
  });

  const data = {
    name: "Emissions Breakdown by Scope",
    children: [
      {
        name: "Scope 1",
        children: [
          { name: "Fuel", value: 100 },
          { name: "Electricity", value: 120 },
        ],
      },
      {
        name: "Scope 2",
        children: [
          { name: "Fuel", value: 150 },
          { name: "Electricity", value: 170 },
        ],
      },
      {
        name: "Scope 3",
        children: [
          { name: "Transport", value: 300 },
          { name: "Waste", value: 330 },
        ],
      },
    ],
  };

  const colors = d3.scaleOrdinal(["#a1d9ff", "#bde4b1", "#ffc1a1"]); // Subtle colors

  useEffect(() => {
    renderGraph();
  }, [visibleScopes]);

  const renderGraph = () => {
    const width = 600;
    const height = 300;
    const margin = { top: 50, right: 50, bottom: 50, left: 80 };

    d3.select(graphRef.current).selectAll("*").remove();

    const svg = d3
      .select(graphRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .style("display", "block")
      .style("margin", "0 auto");

    const filteredData = {
      ...data,
      children: data.children.filter((d) => visibleScopes[d.name]),
    };

    const treemap = d3
      .treemap()
      .size([
        width - margin.left - margin.right,
        height - margin.top - margin.bottom,
      ])
      .padding(1);

    const root = d3
      .hierarchy(filteredData)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);

    treemap(root);

    const cell = svg
      .selectAll("g")
      .data(root.leaves())
      .enter()
      .append("g")
      .attr(
        "transform",
        (d) => `translate(${d.x0 + margin.left},${d.y0 + margin.top})`
      );

    cell
      .append("rect")
      .attr("id", (d) => d.data.name)
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0)
      .attr("fill", (d) => colors(d.parent.data.name));

    cell
      .append("text")
      .attr("x", (d) => (d.x1 - d.x0) / 2)
      .attr("y", (d) => (d.y1 - d.y0) / 2)
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      .text((d) => d.data.name)
      .attr("fill", "#000")
      .attr("font-size", "12px");

    cell
      .append("title")
      .text(
        (d) =>
          `${d.data.name}\nScope: ${d.parent.data.name}\nEmissions: ${d.data.value}`
      );
  };

  const handleToggleScope = (scope) => {
    setVisibleScopes((prev) => ({ ...prev, [scope]: !prev[scope] }));
  };

  return (
    <div>
      <div>
        <h3 style={{ margin: "25px", fontSize: "18px" }}>
          Scope 3 Emissions Breakdown
        </h3>
      </div>
      <div ref={graphRef} style={{ textAlign: "center" }}></div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <FormGroup row style={{ justifyContent: "center" }}>
          {Object.keys(visibleScopes).map((scope) => (
            <FormControlLabel
              key={scope}
              control={
                <Checkbox
                  checked={visibleScopes[scope]}
                  onChange={() => handleToggleScope(scope)}
                  style={{
                    color: colors(scope),
                  }}
                />
              }
              label={scope}
            />
          ))}
        </FormGroup>
      </div>
    </div>
  );
};

export default TreeMap;
