import { DateTime } from "luxon";

export const getFiscalYearRange = (year, fymonth) => {
  let startDate, endDate;

  if (fymonth === 1) {
    startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf("day");
    endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf("day");
  } else {
    startDate = DateTime.fromObject({
      year: year - 1,
      month: fymonth,
      day: 1,
    }).startOf("day");
    endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf(
      "month"
    );
  }

  return { startDate, endDate };
};

export const filterSubmissionsByFiscalYear = (submissions, year, fymonth) => {
  const { startDate, endDate } = getFiscalYearRange(year, fymonth);

  return submissions.filter((submission) => {
    const allDatesWithinRange = submission.reporting_period.every((period) => {
      const periodDate = DateTime.fromFormat(period, "MM-yyyy").startOf(
        "month"
      );
      return periodDate >= startDate && periodDate <= endDate;
    });

    return allDatesWithinRange;
  });
};

export const calculateEmissionByDCF = (
  standardData,
  Submission,
  standardId, //1
  categoryId, //5
  findingKeys, //[‘DPA0136’]
  valueKey, //[‘DPA0138’]
  subCategorykeys, //[1]
  subcategory, //{one:[],two:[],...four:[]}
  locationData = [],
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  let structuredData = [],
    emissionData = [];

  let submission = Submission;
  // Step 1: Validate if submission.response exists
  if (
    !submission ||
    !submission.response ||
    !Array.isArray(submission.response)
  ) {
    submission.error = "No valid response property found in submission.";
    return submission;
  }

  // Step 2: Get the latest reporting period from the submission response
  const reportingPeriod = submission?.reporting_period?.[0];
  if (!reportingPeriod) {
    submission.error = "No reporting_period found in submission response.";
    return submission;
  }

  // Step 3: Parse the reporting period date (MM-yyyy format) and add 1 month
  let lastDate;
  try {
    lastDate = DateTime.fromFormat(reportingPeriod, "MM-yyyy", { zone: "utc" })
      .setZone(timezone)
      .plus({ months: 1 });
  } catch (error) {
    submission.error = `Invalid reporting_period format: ${reportingPeriod}`;
    return submission;
  }

  // Step 4: Format the next month for comparison
  const reportingDate = lastDate.toFormat("MM-yyyy");

  // Step 5: Iterate through standardData and find matching standardId
  const filteredData = standardData.filter((standard) => {
    // Match by standardId
    if (standard.id !== standardId) return false;

    // Step 6: Validate if newEfDates is available
    if (!standard.newEfDates || standard.newEfDates.length === 0) {
      submission.error = "newEfDates is empty or missing in standard.";
      return false;
    }

    // Step 7: Check the date ranges in newEfDates and find the index for matching range
    const dateIndex = standard.newEfDates.findIndex((dateRange) => {
      const startDate = DateTime.fromISO(dateRange.start, {
        zone: "utc",
      }).setZone(timezone);
      const endDate = dateRange.end
        ? DateTime.fromISO(dateRange.end, { zone: "utc" }).setZone(timezone)
        : DateTime.local();

      return lastDate >= startDate && lastDate <= endDate;
    });

    if (dateIndex === -1) {
      submission.error = "No matching date range found in newEfDates.";
      return false;
    }

    const newEfDates = standard.newEfDates[dateIndex];

    if (!newEfDates.newEfs || newEfDates.newEfs.length === 0) {
      submission.error = "newEf is empty or missing in standard.";
      return false;
    }

    const efCatIndex = newEfDates.newEfs.findIndex(
      (x) => x.category === categoryId
    );
    if (efCatIndex === -1) {
      submission.error = `No match found for categoryId: ${categoryId} in newEfs.`;
      return false;
    }

    const newEfItems = newEfDates.newEfs[efCatIndex]?.newEfItems || [];
    if (newEfItems.length === 0) {
      submission.error = "newEfItems is empty or missing in newEfs.";
      return false;
    }
    // Iterate over submission.response and check for matches in newEfItems
    submission.response.forEach((resItem) => {
      const matchedItem = newEfItems.find((item) => {
        return findingKeys.every((key, index) => {
          const subCategoryKey = item[`subcategory${subCategorykeys[index]}`];
          return resItem[key] === subCategoryKey; // Check if key matches corresponding subcategory value
        });
      });

      if (matchedItem) {
        resItem.emission = resItem[valueKey] * (matchedItem.co2e / 1000);
        resItem.ef = matchedItem; // Attach matching newEfItem as 'ef'

        if (submission.dcfId === 11) {
          emissionData.push({
            "Fuel Category": matchedItem.subcat1.title,
            "Fuel Type ": matchedItem.subcat2.title,
            Unit: matchedItem.subcat3.title,
            "Quantity Used": resItem.DPA0336,
            Value: matchedItem.co2e,
            Unit: "kgCO2e",
            "Emission Factor": standardData.find((standard) => {
              return standard.id === standardId;
            })?.title,
            Source: newEfDates.newEfs[efCatIndex].source,
            "Start Date": DateTime.fromISO(
              standard.newEfDates[dateIndex].start,
              { zone: "Asia/Calcutta" }
            ).toFormat("MMM-yyyy"),
            "End Date": standard.newEfDates[dateIndex].end
              ? DateTime.fromISO(standard.newEfDates[dateIndex].end, {
                  zone: "Asia/Calcutta",
                }).toFormat("MMM-yyyy")
              : "Ongoing",
          });

          structuredData.push({
            "Fuel Category": matchedItem.subcat1.title,
            "Fuel Type ": matchedItem.subcat2.title,
            Unit: matchedItem.subcat3.title,
            "Quantity Used": resItem.DPA0336,
          });
        } else if (submission.dcfId === 10) {
          console.log(resItem.DPA0138);
          emissionData.push({
            "Type of AC/ Chiller / freezer used": resItem.DPA0135,
            "Type of gas refilled": matchedItem.subcat1.title,
            "Gas Refilled": resItem.DPA0138,
            Value: matchedItem.co2e,
            Unit: "kgCO2e",
            "Emission Factor": standardData.find((standard) => {
              return standard.id === standardId;
            })?.title,
            Source: newEfDates.newEfs[efCatIndex].source,
            "Start Date": DateTime.fromISO(
              standard.newEfDates[dateIndex].start,
              { zone: "Asia/Calcutta" }
            ).toFormat("MMM-yyyy"),
            "End Date": standard.newEfDates[dateIndex].end
              ? DateTime.fromISO(standard.newEfDates[dateIndex].end, {
                  zone: "Asia/Calcutta",
                }).toFormat("MMM-yyyy")
              : "Ongoing",
          });
          structuredData.push({
            "Type of AC/ Chiller / freezer used": resItem.DPA0135,
            "Type of gas refilled": matchedItem.subcat1.title,
            "Gas Refilled": resItem.DPA0138,
          });
        }
      } else {
        if (submission.dcfId === 11) {
          emissionData.push({
            "Fuel Category": subcategory.one.find(
              (x) => x.id === resItem.DPA0130
            )?.title,
            "Fuel Type ": subcategory.one.find((x) => x.id === resItem.DPA0131)
              ?.title,
            Unit: subcategory.one.find((x) => x.id === resItem.DPA0132)?.title,
            "Quantity Used": resItem.DPA0336,
            Value: "NA",
            Unit: "kgCO2e",
            "Emission Factor": standardData.find((standard) => {
              return standard.id === standardId;
            })?.title,
            Source: newEfDates.newEfs[efCatIndex].source,
            "Start Date": DateTime.fromISO(
              standard.newEfDates[dateIndex].start,
              { zone: "Asia/Calcutta" }
            ).toFormat("MMM-yyyy"),
            "End Date": standard.newEfDates[dateIndex].end
              ? DateTime.fromISO(standard.newEfDates[dateIndex].end, {
                  zone: "Asia/Calcutta",
                }).toFormat("MMM-yyyy")
              : "Ongoing",
          });
          structuredData.push({
            "Fuel Category": subcategory.one.find(
              (x) => x.id === resItem.DPA0130
            )?.title,
            "Fuel Type ": subcategory.one.find((x) => x.id === resItem.DPA0131)
              ?.title,
            Unit: subcategory.one.find((x) => x.id === resItem.DPA0132)?.title,
            "Quantity Used": resItem.DPA0336,
          });
        } else if (submission.dcfId === 10) {
          emissionData.push({
            "Type of AC/ Chiller / freezer used": resItem.DPA0135,
            "Type of gas refilled": "Gas Type Not Found",
            "Gas Refilled": resItem.DPA0138,
            Value: "NA",
            Unit: "kgCO2e",
            "Emission Factor": standardData.find((standard) => {
              return standard.id === standardId;
            })?.title,
            Source: newEfDates.newEfs[efCatIndex].source,
            "Start Date": DateTime.fromISO(
              standard.newEfDates[dateIndex].start,
              { zone: "Asia/Calcutta" }
            ).toFormat("MMM-yyyy"),
            "End Date": standard.newEfDates[dateIndex].end
              ? DateTime.fromISO(standard.newEfDates[dateIndex].end, {
                  zone: "Asia/Calcutta",
                }).toFormat("MMM-yyyy")
              : "Ongoing",
          });
          structuredData.push({
            "Type of AC/ Chiller / freezer used": resItem.DPA0135,
            "Type of gas refilled": "Gas Type Not Found",
            "Gas Refilled": resItem.DPA0138,
          });
        }
      }
    });

    return true;
  });

  // Step 11: Check if no match was found, and add an error to submission
  if (structuredData.length === 0) {
    submission.error = "No matching newEfItem found or invalid data.";
    return submission;
  }

  // Step 12: Attach the filtered data to the submission object
  submission.calculatedResponse = filteredData;
  submission.structuredData = structuredData;
  submission.emissionData = emissionData;

  return submission;
};
